import {useInView} from "react-intersection-observer";

export const SecondChelseaRoomMob = () => {

    const options = {
        threshold: 0.1,
        triggerOnce: true
    }

    const txtOptions = {
        threshold: 0.4,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(options)
    const [ref3, inView3] = useInView(options)
    const [ref4, inView4] = useInView(txtOptions)

    return (
        <section className="second-ch-room">
            <div className="second-ch-room-mob__wrapper">
                <div ref={ref1} className="second-ch-room-mob__top">
                    {inView1 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "92vw", height: "56vw"}}
                            src="Chelsea/SecondTopMob.jpg"
                            alt="#"
                        />
                    }

                    {inView1 &&
                        <img
                            className="second-ch-room__right opacity-animation-1_5s"
                            style={{width: "84vw", height: "112vw"}}
                            src="Chelsea/SecondLeftMob.jpg"
                            alt="#"
                        />
                    }
                </div>

                <div ref={ref4} style={{height: "94vw"}}>
                    {inView4 &&
                        <p className="second-ch-room-mob__bottom opacity-animation-1_5s">
                            In this captivating dining room, the design of the table is inviting and artfully reflecting the marble
                            countertops in the neighboring kitchen. Guided by our client's taste for a distinct and refined
                            aesthetic, we meticulously curated a sophisticated, high-end look that sets the stage for
                            unforgettable gatherings while seamlessly enhancing the contemporary theme that flows
                            throughout the entire house.
                        </p>
                    }
                </div>

                <div ref={ref3} style={{height: "60vw"}}>
                    {inView3 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "92vw", height: "60vw"}}
                            src="Chelsea/SecondRightMob.jpg"
                            alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}