import "../../styles/nottingHillPageMob.scss"
import {useInView} from "react-intersection-observer";

export const SeventhNottingRoomMob = () => {

    const optionsForLowHeight = {
        threshold: 0.2,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(optionsForLowHeight)
    const [ref2, inView2] = useInView(optionsForLowHeight)
    const [ref3, inView3] = useInView(optionsForLowHeight)

    return (
        <section className="seventh-nt-room-mob">
            <div className="seventh-nt-room-mob__wrapper">
                <div ref={ref1} style={{height:  "64vw"}}>
                    {inView1 &&
                        <img
                            src="nottingHill/sesec-top-mob.jpg"
                            style={{width: "84vw", height: "64vw"}}
                            className="seventh-nt-room-mob__top opacity-animation-1_5s"
                            alt="#"
                        />
                    }
                </div>

                <div ref={ref2} className="seventh-nt-room-mob__bottom" style={{height: "133.3vw"}}>
                    {inView2 && <div className="seventh-nt-room-mob__line line opacity-animation-2s"></div>}

                    {inView2 &&
                        <img
                            className="opacity-animation-1_5s"
                            src="nottingHill/sesec-mid-mob.jpg"
                            style={{width: "48.3vw", height: "133.3vw"}}
                            alt="#"
                        />
                    }
                </div>

                <div ref={ref3} style={{height: "60.6vw"}} className="seventh-nt-room-mob__top">
                    {inView3 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "39.33vw", height: "51.3vw"}}
                            src="nottingHill/sesec-bot-left-mob.jpg"
                            alt="#"
                        />
                    }
                    {inView3 &&
                        <img
                            className="opacity-animation-2s"
                            style={{width: "39.33vw", height: "51.3vw"}}
                            src="nottingHill/sesec-bot-right-mob.jpg"
                            alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}
