import "../../styles/twickenhamPage.scss"
import {useInView} from "react-intersection-observer";

export const SixthTwickenhamRoomMob = () => {

    const optionOne = {
        threshold: 0.1,
        triggerOnce: true
    }

    const txtOption = {
        threshold: 0.5,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(optionOne)
    const [ref2, inView2] = useInView(txtOption)
    const [ref3, inView3] = useInView(optionOne)

    return (
        <section className="sixth-tw-room-mob">
            <div className="sixth-tw-room-mob__wrapper">
                <div ref={ref1} style={{height: "66.6vw"}}>
                    {inView1 &&
                        <img
                            style={{width: "92vw", height: "66.6vw"}}
                            className="sixth-tw-room-mob__top opacity-animation-1_5s"
                            src="TwicknhamPage/TwickenhamSixRoomTopMob.jpg"
                            alt="#"
                        />
                    }
                </div>


                <div ref={ref2} style={{height: "83.5vw"}}>
                    {inView2 &&
                        <p className="sixth-tw-room-mob__paragraph opacity-animation-1_5s">
                            Inspired by the child's own desire to be an astronaut, we curated an enchanting atmosphere with
                            deep blue, starry wallpaper that sparks their imagination and creativity. We extended the
                            celestial theme by incorporating elements throughout the room that echoed the desired colour
                            palette and displaying the child’s pre-owned toys.
                        </p>
                    }
                </div>

                <div ref={ref3} style={{height: "129.3vw"}}>
                    {inView3 &&
                        <img style={{width: "84vw", height: "129.3vw"}}
                             src="TwicknhamPage/TwickenhamSixRoomBotMob.jpg"
                             className="sixth-tw-room-mob__bottom opacity-animation-1_5s"
                             alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}