import {useInView} from "react-intersection-observer";
import "../../styles/nottingHillPageMob.scss"

export const TenthNottingRoomMob = () => {

    const options = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(options)
    const [ref2, inView2] = useInView(options)
    const [ref3, inView3] = useInView(options)

    return (
        <section className="tenth-nt-room-mob">
            <div className="tenth-nt-room-mob__wrapper">
                <div ref={ref1} style={{height: "129.3vw"}}>
                    {inView1 &&
                        <img
                            style={{width: "84vw", height: "129.3vw"}}
                            className="tenth-nt-room-mob__right opacity-animation-1_5s"
                            src="nottingHill/tensec-top-mob.jpg"
                            alt="#"
                        />
                    }
                </div>

                <div ref={ref2} style={{height: "81.3vw"}}>
                    {inView2 &&
                        <div className="tenth-nt-room-mob__paragraph">
                            <p className=" opacity-animation-2s">
                                The concepts Ksenia produced matched our brief perfectly and she was always open to further suggestions
                                or requests, even after we moved in and realized we wanted to add a few things. We would have never been able
                                to design, manage and execute a project of this scale so beautifully and are so glad we had K|D to help us.
                            </p>
                            <p className="tenth-nt-room-mob__quote opacity-animation-2s">Client feedback</p>
                        </div>
                    }
                </div>

                <div ref={ref3} style={{height: "66.6vw"}}>
                    {inView3 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "84vw", height: "66.6vw"}}
                            src="nottingHill/tensec-bottom-mob.jpg"
                            alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}