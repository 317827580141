import "../../styles/nottingPage.scss"

export const ThirdNottingRoom = () => {

    return (
        <section className="third-nt-room photo-section">
            <div className="third-nt-room__wrapper">
                <div className="third-nt-room__left opacity-animation-2_5s">
                    <img
                        style={{width: "26.6vw", height: "20vw"}}
                        src="nottingHill/tsec-left.jpg"
                        alt="#"
                    />
                    <p className="third-nt-room__text">
                        Ksenia Design were totally invaluable when it came to our large house redesign project.
                        From the initial consultation, where Ksenia prepared a visual quiz for Tom and I to choose which interior images attracted
                        us so she could gauge our tastes...
                    </p>

                    <p className="third-nt-room__quote">Client feedback</p>
                </div>


                <img
                    style={{width: "54.4vw", height: "40.1vw"}}
                    className="third-nt-room__right opacity-animation-1_5s"
                    src="nottingHill/tsec-right.jpg"
                    alt="#"
                />
            </div>
        </section>
    )
}