import {NavBar} from "./NavBar";
import "../styles/utilHeader.scss"

export const UtilHeader = ({ bgName, bgColorName, pageTitle }) => {
    return (
        <header className="util-header">
            <NavBar />
            <div className={`util-header__color-bg ${bgColorName}`}></div>
            <div className={`util-header__bg ${bgName}`}></div>

            <p className="opacity-animation-1_5s">{pageTitle}</p>
        </header>
    )
}