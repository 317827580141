import "../../styles/twickenhamPage.scss"
import {useInView} from "react-intersection-observer";

export const FifthChelseaRoomMob = () => {

    const options = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(options)
    const [ref2, inView2] = useInView(options)

    return (
        <section className="fifth-ch-room-mob">
            <div className="fifth-ch-room-mob__wrapper">
                <div ref={ref1} style={{height: "122vw"}}>
                    {inView1 &&
                        <img
                            className="fifth-ch-room-mob__top opacity-animation-1_5s"
                            style={{width: "84vw", height: "122vw"}}
                            src="Chelsea/FifthLeftMob.jpg"
                            alt="#"
                        />
                    }
                </div>


                <div ref={ref2} className="fifth-ch-room-mob__bottom">
                    {inView2 &&
                        <img
                            style={{width: "56.6vw", height: "66.6vw"}}
                            className="fifth-ch-room__right opacity-animation-1_5s"
                            src="Chelsea/FifthRightTopMob.jpg"
                            alt="#"
                        />
                    }
                    {inView2 && <div className="fifth-ch-room-mob__line line opacity-animation-2_5s"></div>}
                    {inView2 &&
                        <img
                            style={{width: "56.6vw", height: "66.6vw"}}
                            className="fifth-ch-room__right opacity-animation-1_5s"
                            src="Chelsea/FifthRightBotMob.jpg"
                            alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}