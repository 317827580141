import "../styles/chelsea.scss"
import {useEffect} from "react";
import {Link} from "react-router-dom";

const ChelseaShowcase = () => {
    useEffect(() => {
        showHeader()
    }, [])

    function showHeader () {
        let h = 0;
        let mul = 1
        const twHeader = document.getElementById("ch-header")
        setInterval(() => {
            if (h >= 8.5) return;
            if (h >= 3.5) mul = 0.7
            if (h >= 6.5) mul = 0.4
            if (h >= 8) mul = 0.05
            h += 0.12 * mul
            twHeader.style.height = `${h}vw`;
        }, 7)
    }

    return (
        <section className="chelsea">
            <div id="ch-header" className="chelsea__header">
                <p>Chelsea</p>
            </div>

            <div className="chelsea__container">

                <Link to="/chelsea" className="chelsea__arrow">
                    <i className="chelsea__arrow_right"></i>
                </Link>

                <div className="chelsea__imgs">
                    <div className="chelsea__imgs_wrapper">
                        <img className="chelsea__imgs_two" src="chelsea_thph_heavy.jpg" loading="lazy" height="14.6vw" width="19.53vw" alt="#"/>
                        <img className="chelsea__imgs_three" src="chelsea_sph_heavy.jpg" loading="lazy" height="14.6vw" width="19.53vw" alt="#"/>
                    </div>
                    <img className="chelsea__imgs_one" src="chelsea_fph_heavy.jpg" loading="lazy" height="29.9vw" width="19.53vw" alt="#"/>
                </div>

                <div className="chelsea__wrapper">
                    <div className="chelsea__ticket">
                        <div className="chelsea__blocker"></div>

                        <div className="chelsea__ticket_desc">
                            <p>
                                In this project, we highlighted treasured artwork in a contemporary space by infusing
                                serene, cool tones, and incorporating our client's love for the colour blue.
                            </p>
                        </div>

                        <Link to="/chelsea" className="chelsea__ticket_btn">
                            <img src="arrow_bold.svg" alt="#"/>
                            <p>View more</p>
                        </Link>
                    </div>
                </div>

            </div>


            <p className="twickenham__bg-txt">Chel</p>
            <p className="twickenham__bg-txt">sea</p>


        </section>
    )
}

export default ChelseaShowcase;