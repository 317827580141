import "../../styles/nottingHillPageMob.scss"
import {useInView} from "react-intersection-observer";

export const FifthNottingRoomMob = () => {

    const options = {
        threshold: 0.1,
        triggerOnce: true
    }

    const txtOptions = {
        threshold: 0.4,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(options)
    const [ref3, inView3] = useInView(options)
    const [ref4, inView4] = useInView(txtOptions)

    return (
        <section className="fifth-nt-room-mob">
            <div className="fifth-nt-room-mob__wrapper">
                <div ref={ref1} className="fifth-nt-room-mob__top">
                    {inView1 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "84vw", height: "53.3vw"}}
                            src="nottingHill/fisec-top-mob.jpg"
                            alt="#"
                        />
                    }
                </div>

                <div ref={ref3} style={{height: "78.2vw"}}>
                    {inView3 &&
                        <div className="fifth-nt-room-mob__text">
                            <p className="opacity-animation-1_5s">
                                ...right through to the final installation, where she and her colleague Daria worked (quite literally)
                                day and night to ensure everything was in place for our move-in date. Every detail was handled meticulously
                                and Ksenia project managed all the suppliers on our behalf, including electricians, AV specialists, decorators,
                                curtain makers and furniture suppliers.
                            </p>
                            <p className="fifth-nt-room-mob__quote opacity-animation-2s">Client feedback</p>
                        </div>
                    }
                </div>

                <div ref={ref4} style={{height: "50.6vw"}}>
                    {inView4 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "92vw", height: "50.6vw"}}
                            src="nottingHill/fisec-bottom-mob.jpg"
                            alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}