import {useMatchMedia} from "../hooks";
import {
    Header,
    MobileHeader,
    Footer,
    TwickenhamShowcaseMob,
    ChelseaShowcaseMob,
    AboutMob
} from "../components";
import "../styles/backgrounds.scss"
import {FooterMob} from "../components/FooterMob";
import {useLayoutEffect} from "react";
import About from "../components/About";
import TwickenhamShowcase from "../components/TwickenhamShowcase";
import ChelseaShowcase from "../components/ChelseaShowcase";
import {useInView} from "react-intersection-observer";
import NottingHillShowcase from "../components/NottingHillShowcase";
import {NottingHillShowcaseMob} from "../components/NottingHillShowcaseMob";


export const HomePage = () => {
    const { isMobile } = useMatchMedia()

    useLayoutEffect(() => {
        if (window.location.hash === "#footer") {
            window.scrollTo(0, document.body.scrollHeight)
            setTimeout(() => {
                window.location.hash = "contacts"
            }, 1000)
        } else {
            window.scrollTo(0, 0)
        }
    }, []);

    const options = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [nottingHillRef, nottingHillInView] = useInView(options)
    const [twickenhamRef, twickenhamInView] = useInView(options)
    const [chelseaRef, chelseaInView] = useInView(options)
    const [aboutRef, aboutInView] = useInView(options)

    return (
        <main>
            {isMobile ?
                    <MobileHeader
                    bgName={"home-bg"}
                    pageTitle={"Ksenia Design"}
                /> :
                    <Header
                    bgName={"home-bg"}
                    pageTitle={"Ksenia Design"}
                />}
            {isMobile ?
                <section className="bg home-page">
                    <div ref={nottingHillRef} className="nottinghill-mob">
                        {nottingHillInView && <NottingHillShowcaseMob/>}
                    </div>
                    <div ref={chelseaRef} className="chelsea-mob">
                        {chelseaInView && <ChelseaShowcaseMob/>}
                    </div>
                    <div ref={twickenhamRef} className="twickenham-mob">
                        {twickenhamInView && <TwickenhamShowcaseMob/>}
                    </div>
                    <div ref={aboutRef} style={{height: "150.02vw"}}>
                        {aboutInView && <AboutMob/>}
                    </div>
                    <FooterMob />
                </section>
                :
                <section className="bg home-page">
                    <div ref={nottingHillRef} className="nottinghill">
                        {nottingHillInView && <NottingHillShowcase/>}
                    </div>
                    <div ref={chelseaRef} className="chelsea">
                        {chelseaInView && <ChelseaShowcase/>}
                    </div>
                    <div ref={twickenhamRef} className="twickenham">
                        {twickenhamInView && <TwickenhamShowcase/>}
                    </div>
                    <div ref={aboutRef} style={{height: "32vw"}}>
                        {aboutInView && <About/>}
                    </div>
                    <Footer />
                </section>
            }
        </main>
    )
}