import "../../styles/twickenhamPage.scss"
import {useInView} from "react-intersection-observer";

export const SeventhChelseaRoomMob = () => {

    const options = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(options)
    const [ref2, inView2] = useInView(options)

    return (
        <section className="seventh-ch-room-mob">
            <div className="seventh-ch-room-mob__wrapper ">
                <div ref={ref1} className="seventh-ch-room-mob__top">
                    {inView1 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "60vw", height: "57.3vw"}}
                            src="Chelsea/SeventhRightTopMob.jpg"
                            alt="#"
                        />
                    }
                    {inView1 && <div className="seventh-ch-room-mob__line line opacity-animation-2_5s"></div>}
                    {inView1 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "60vw", height: "57.3vw"}}
                            src="Chelsea/SeventhRightBotMob.jpg"
                            alt="#"
                        />
                    }
                </div>

                <div ref={ref2} style={{height: "112vw"}}>
                    {inView2 &&
                        <img
                            style={{width: "84vw", height: "112vw"}}
                            className="seventh-ch-room-mob__bottom opacity-animation-1_5s"
                            src="Chelsea/SeventhLeftMob.jpg"
                            alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}