import "../../styles/nottingPage.scss"

export const FirstNottingRoom = () => (
    <section className="first-nt-room">
        <div className="first-nt-room__wrapper">
            <div className="first-nt-room__left">
                <img className="opacity-animation-1_5s"
                     style={{width: "60.4vw", height: "47.5vw"}}
                     src="nottingHill/fsec-left.jpg"
                     alt="#"
                />
            </div>

            <div className="first-nt-room__right">
                <img
                    className="first-nt-room__right_img opacity-animation-2s"
                    style={{width: "23.4vw", height: "21.875vw"}}
                    src="nottingHill/fsec-right-fir.jpg"
                    alt="#"
                />
                <img
                    className="first-nt-room__right_img opacity-animation-3s"
                    style={{width: "23.4vw", height: "21.875vw"}}
                    src="nottingHill/fsec-right-sec.jpg"
                    alt="#"
                />
            </div>
            <div className="first-nt-room__line opacity-animation-3_5s"></div>
        </div>
    </section>
)
