import "../styles/footer.scss"
import {Link} from "react-router-dom";
import ClipboardJS from 'clipboard';
import {useEffect, useRef, useState} from "react";

export const Footer = () => {
    const buttonRef = useRef(null);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        const clipboard = new ClipboardJS(buttonRef.current);

        clipboard.on('success', (e) => {
            setShowMessage(true);

            setTimeout(() => {
                setShowMessage(false);
            }, 3000);
        });

        clipboard.on('error', (e) => {
            console.log('Error when try to copy: ', e.text);
        });

        return () => {
            clipboard.destroy();
        };
    }, []);

    return (
        <footer id="footer" className="footer">
            <div className="footer__wrapper">
                
                <i className="footer__angle"></i>
                
                <div className="footer__content">
                    <div className="footer__column">
                        <p className="footer__column_header">Our Design Studio:</p>
                        <p className="footer__column_address">
                            12 Grosvenor Rd.
                            East Grinstead
                            RH19 1HT
                        </p>
                        <button
                            className="footer__column_bott"
                            ref={buttonRef}
                            data-clipboard-text="+447568562242"
                            style={{"width": "13.3vw"}}
                            >(+44)75 6856 2242
                        </button>
                        {showMessage && <div className="footer__column_message opacity-animation-0_5s">Number copied!</div>}
                    </div>

                    <img src="./Ksenia_Design_logo.svg" height="90%" width="30%" alt="Ksenia Design"/>

                    <div className="footer__column">
                        <p className="footer__column_header">Get in touch:</p>
                        <ul className="footer__column_list">
                            <li>
                                <Link to="https://www.pinterest.co.uk/kseniainterior/"
                                      target="_blank">Pinterest
                                </Link>
                            </li>
                            <li>
                                <Link to="https://www.instagram.com/ksenia.interiors/"
                                      target="_blank">Instagram
                                </Link>
                            </li>
                            <li>
                                <Link to="https://www.facebook.com/Ksenia-Design-Ltd-112597721014951"
                                      target="_blank">Facebook
                                </Link>
                            </li>
                            <li><a href="mailto:ksenia@kseniadesign.com">Email</a></li>
                        </ul>
                        <p className="footer__column_bott">PRIVACY POLICY</p>
                    </div>
                </div>
                
                <i className="footer__angle"></i>

            </div>
        </footer>
    )
}