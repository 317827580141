import "../../styles/chelseaPage.scss"
import {useInView} from "react-intersection-observer";

export const FirstChelseaRoom = () => {

    const optionsForHighHeight = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref, InView] = useInView(optionsForHighHeight)

    return (
        <section className="first-ch-room">
            <div className="first-ch-room__wrapper">
                <div className="first-ch-room__left">
                    <img
                        className="opacity-animation-1_5s"
                        style={{width: "16.6vw", height: "21.875vw"}}
                        src="Chelsea/FirstLeftTop_light.jpg"
                        alt="#"
                    />
                    <div className="first-ch-room__line line opacity-animation-3s"></div>
                    <img
                        className="opacity-animation-2_5s"
                        style={{width: "16.6vw", height: "21.875vw"}}
                        src="Chelsea/FirstLeftBot_light.jpg"
                        alt="#"
                    />
                </div>

                <img
                    style={{width: "60.4vw", height: "47.5vw"}}
                    className="first-ch-room__right opacity-animation-3s"
                    src="Chelsea/FirstRight_light.jpg"
                    alt="#"
                />
            </div>

            <div ref={ref} className="first-ch-room__bottom wrapper photo-section">
                {InView &&
                    <img
                        className="opacity-animation-2_5s"
                        style={{width: "54.4vw", height: "40.1vw"}}
                        src="Chelsea/FirstBottom_light.jpg"
                        alt="#"
                    />
                }
                {InView &&
                    <p className="opacity-animation-1_5s">
                        In this design project, we had the pleasure of working with a young family who wanted to
                        showcase their deep appreciation for their existing artwork. Our approach was to create a space
                        where these cherished pieces were displayed while blending with the contemporary ambiance.
                    </p>
                }
            </div>
        </section>
    )
}
