import "../../styles/nottingPage.scss"

export const EleventhNottingRoom = () => (
    <section className="eleventh-nt-room photo-section">
        <div className="eleventh-nt-room__wrapper">
            <img
                className="eleventh-nt-room__left opacity-animation-1_5s"
                style={{width: "28.125vw", height: "28.125vw"}}
                src="nottingHill/elsec-left.jpg"
                alt="#"
            />

            <img
                style={{width: "53.4vw", height: "41.6vw"}}
                className="eleventh-nt-room__right opacity-animation-2s"
                src="nottingHill/elsec-right.jpg"
                alt="#"
            />

            <div className="eleventh-nt-room__line opacity-animation-3s"></div>
        </div>
    </section>
)
