import "../../styles/twickenhamPage.scss"

export const ThirdChelseaRoom = () => {
    return (
        <section className="third-ch-room photo-section">
           <div className="third-ch-room__wrapper wrapper">
               <div className="third-ch-room__left">
                   <img
                       className="opacity-animation-2_5s"
                       style={{width: "22.3vw", height: "22.3vw"}}
                       src="Chelsea/ThirdLeftTop_light.jpg"
                       alt="#"
                   />
                   <div className="third-ch-room__line line opacity-animation-3s"></div>
                   <img
                       className="opacity-animation-2s"
                       style={{width: "22.3vw", height: "22.3vw"}}
                       src="Chelsea/ThirdLeftBot_light.jpg"
                       alt="#"
                   />
               </div>

               <img
                   style={{width: "48.1vw", height: "70vw"}}
                   className="third-ch-room__right opacity-animation-1_5s"
                   src="Chelsea/ThirdRight_light.jpg"
                   alt="#"
               />
           </div>
        </section>
    )
}