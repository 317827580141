import {Header, MobileHeader} from "../components";
import {useMatchMedia} from "../hooks";
import {
    FifthChelseaRoom,
    FirstChelseaRoom,
    FourthChelseaRoom,
    SecondChelseaRoom,
    SeventhChelseaRoom,
    SixthChelseaRoom,
    ThirdChelseaRoom
} from "../components/Chelsea";
import {useLayoutEffect} from "react";
import {GoForwardFooter} from "../components/GoForwardFooter";
import {
    FifthChelseaRoomMob,
    FirstChelseaRoomMob,
    FourthChelseaRoomMob,
    SecondChelseaRoomMob,
    SeventhChelseaRoomMob,
    SixthChelseaRoomMob, ThirdChelseaRoomMob
} from "../components/ChelseaMob";
import {useInView} from "react-intersection-observer";
import {GetBackFooter} from "../components/GetBackFooter";
import {GoForwardFooterMob} from "../components/GoForwardFooterMob";
import {GetBackFooterMob} from "../components/GetBackFooterMob";

export const ChelseaPage = () => {
    const { isMobile } = useMatchMedia()

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const optionsForHighHeight = {
        threshold: 0.05,
        triggerOnce: true
    }

    const [first, firstInView] = useInView(optionsForHighHeight)
    const [second, secondInView] = useInView(optionsForHighHeight)
    const [third, thirdInView] = useInView(optionsForHighHeight)
    const [fourth, fourthInView] = useInView(optionsForHighHeight)
    const [fifth, fifthInView] = useInView(optionsForHighHeight)
    const [sixth, sixthInView] = useInView(optionsForHighHeight)
    const [seventh, seventhInView] = useInView(optionsForHighHeight)

    return (
        <main>
            {isMobile ?
                <MobileHeader
                    bgName={"chelsea-bg"}
                    pageTitle={"Chelsea"}
                />
                :
                <Header
                    bgName={"chelsea-bg"}
                    pageTitle={"Chelsea"}
                />
            }

            {isMobile ?
                <section className="bg chelsea-page">
                    <div ref={first} style={{height: "337vw"}}>
                        {firstInView && <FirstChelseaRoomMob/>}
                    </div>
                    <div ref={second} style={{height: "325.6vw"}}>
                        {secondInView && <SecondChelseaRoomMob/>}
                    </div>
                    <div ref={third} style={{height: "177.3vw"}}>
                        {thirdInView && <ThirdChelseaRoomMob/>}
                    </div>
                    <div ref={fourth} style={{height: "300.3vw"}}>
                        {fourthInView && <FourthChelseaRoomMob/>}
                    </div>
                    <div ref={fifth} style={{height: "266.6vw"}}>
                        {fifthInView && <FifthChelseaRoomMob/>}
                    </div>
                    <div ref={sixth} style={{height: "287.3vw"}}>
                        {sixthInView && <SixthChelseaRoomMob/>}
                    </div>
                    <div ref={seventh} style={{height: "236.3vw"}}>
                        {seventhInView && <SeventhChelseaRoomMob/>}
                    </div>
                    <GetBackFooterMob folderName="nottingHill" heading="NOTTING HILL" link="nottinghill" />
                    <GoForwardFooterMob folderName="Chelsea" heading="TWICKENHAM" link="twickenham" />
                </section>
                :
                <section className="bg chelsea-page">
                    <div ref={first} style={{height: "95.05vw"}}>
                        {firstInView && <FirstChelseaRoom/>}
                    </div>
                    <div ref={second} style={{height: "119.6vw"}}>
                        {secondInView && <SecondChelseaRoom/>}
                    </div>
                    <div ref={third} style={{height: "70.05vw"}}>
                        {thirdInView && <ThirdChelseaRoom/>}
                    </div>
                    <div ref={fourth} style={{height: "109.4vw"}}>
                        {fourthInView && <FourthChelseaRoom/>}
                    </div>
                    <div ref={fifth} style={{height: "65.10vw"}}>
                        {fifthInView && <FifthChelseaRoom/>}
                    </div>
                    <div ref={sixth} style={{height: "72.15vw"}}>
                        {sixthInView && <SixthChelseaRoom/>}
                    </div>
                    <div ref={seventh} style={{height: "65.15vw"}}>
                        {seventhInView && <SeventhChelseaRoom/>}
                    </div>

                    <div style={{height: "15.625vw"}}></div>

                    <div className="footer-list" style={{height: "14vw"}}>
                        <GetBackFooter folderName="nottingHill" heading="NOTTING HILL" link="nottinghill" />
                        <div className="footer-spliter"></div>
                        <GoForwardFooter folderName="Chelsea" heading="TWICKENHAM" link="twickenham" />
                    </div>

                    <div style={{height: "15.625vw"}}></div>
                </section>
            }

        </main>
    )
}