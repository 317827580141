import "../styles/nottinghill.scss"
import {useEffect} from "react";
import {Link} from "react-router-dom";

const NottingHillShowcase = () => {
    useEffect(() => {
        showHeader()
    })

    function showHeader () {
        let h = 0;
        let mul = 1
        const twHeader = document.getElementById("notting-header")
        setInterval(() => {
            if (h >= 8.5) return;
            if (h >= 3.5) mul = 0.7
            if (h >= 6.5) mul = 0.4
            if (h >= 8) mul = 0.05
            h += 0.12 * mul
            twHeader.style.height = `${h}vw`;
        }, 7)
    }

    return (
        <section className="nottinghill">
            <div id="notting-header" className="nottinghill__header opacity-loading-1s">
                <p id="tw-header-txt" className="load-header-text-opacity ">Notting Hill</p>
            </div>

            <div className="nottinghill__container">
                <div id="tw-ticket-wrapper" className="nottinghill__wrapper opacity-loading-1s">
                    <div id="tw-ticket" className="nottinghill__ticket header-opacity-2s">
                        <div className="nottinghill__blocker"></div>

                        <div className="nottinghill__ticket_desc">
                            <p>
                                Having worked with the client’s family previously it was a great honour for us to design their new home in Notting Hill.
                                This project reflects the client's love for Australian nature and earthy colours.
                            </p>
                        </div>

                        <Link to="/nottinghill" className="nottinghill__ticket_btn">
                            <p>View more</p>
                            <img src="arrow_bold.svg" alt="#"/>
                        </Link>
                    </div>
                </div>

                <div id="tw-images" className="nottinghill__imgs">
                    <img id="first-img" className="nottinghill__imgs_one header-opacity-1_7s" src="notting_fph_light.jpg" loading="lazy" height="29.9vw" width="19.53vw" alt="#"/>
                    <div className="nottinghill__imgs_wrapper">
                        <img id="second-img" className="nottinghill__imgs_two header-opacity-2_5s" src="notting_sph_light.jpg" loading="lazy" height="14.6vw" width="19.53vw" alt="#"/>
                        <img id="third-img" className="nottinghill__imgs_three header-opacity-3_3s" src="notting_tph_light.jpg" loading="lazy" height="14.6vw" width="19.53vw" alt="#"/>
                    </div>
                </div>

                <Link to="/nottinghill" id="arrow-block" className="nottinghill__arrow arrow-from-right-to-left">
                    <i className="nottinghill__arrow_right"></i>
                </Link>
            </div>

            <p id="bg-txt" className="nottinghill__bg-txt">Notting</p>
            <p id="bg-txt" className="nottinghill__bg-txt">Hill</p>
        </section>
    )
}

export default NottingHillShowcase;