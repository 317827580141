import "../styles/nottinghill.scss"
import {Link} from "react-router-dom";
import {useEffect} from "react";

export const NottingHillShowcaseMob = () => {
    useEffect(() => {
        showHeader()
    }, [])

    function showHeader () {
        let h = 0;
        let mul = 1
        const twHeader = document.getElementById("notting-header")
        setInterval(() => {
            if (h >= 15) return;
            if (h >= 7) mul = 0.7
            if (h >= 12.25) mul = 0.4
            if (h >= 14.5) mul = 0.05
            h += 0.15 * mul
            twHeader.style.height = `${h}vw`;
        }, 7)
    }

    return (
        <section className="nottinghill-mob">
            <img style={{width: "84vw", height: "84vw"}} loading="lazy" className="nottinghill-mob__img" src="nh_showcase_mob.jpg"  alt="#"/>

            <div id="notting-header" className="nottinghill-mob__header">
                <p>Notting Hill</p>
            </div>

            <div className="nottinghill-mob__ticket">
                <p className="nottinghill-mob__ticket_desc">                        
                    Having worked with the client’s family previously it was a great honour for us to design their new home in Notting Hill.
                    This project reflects the client's love for Australian nature. 
                </p>
                <Link to='/nottinghill' className="nottinghill-mob__ticket_btn">
                    <p>View More</p>
                    <img src="arrow_bold.svg" alt="#"/>
                </Link>
            </div>

            <p className="nottinghill__bg-txt mob">N<br/>o<br/>t<br/>t<br/>i</p>
            <p className="nottinghill__bg-txt mob">ngHill</p>
        </section>
    )
}
