import "../styles/chelsea.scss"
import {Link} from "react-router-dom";
import {useEffect} from "react";

export const ChelseaShowcaseMob = () => {
    useEffect(() => {
        showHeader()
    }, [])

    function showHeader () {
        let h = 0;
        let mul = 1
        const twHeader = document.getElementById("ch-header")
        setInterval(() => {
            if (h >= 15) return;
            if (h >= 7) mul = 0.7
            if (h >= 12.25) mul = 0.4
            if (h >= 14.5) mul = 0.05
            h += 0.15 * mul
            twHeader.style.height = `${h}vw`;
        }, 7)
    }

    return (
        <section className="chelsea-mob">
            <img style={{width: "84vw", height: "84vw"}} className="chelsea-mob__img" src="./chelsea_fph_mob.jpg" alt="#"/>

            <div id="ch-header" className="chelsea-mob__header">
                <p>Chelsea</p>
            </div>

            <div className="chelsea-mob__ticket">
                <p className="chelsea-mob__ticket_desc">
                    In this project, we highlighted treasured artwork in a contemporary space by<br/> infusing
                    serene, cool tones, and incorporating our client's <br/> love for the colour blue.
                </p>

                <Link to='/chelsea' className="chelsea-mob__ticket_btn">
                    <p>View More</p>
                    <img src="arrow_bold.svg" alt="#"/>
                </Link>
            </div>

            <p className="chelsea-mob__bg-txt">C<br/>h<br/>e<br/>l</p>
            <p className="chelsea-mob__bg-txt">sea</p>
        </section>
    )
}