import "../../styles/twickenhamPageMob.scss"
import {useInView} from "react-intersection-observer";

export const FifthTwickenhamRoomMob = () => {

    const optionsForLowHeight = {
        threshold: 0.2,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(optionsForLowHeight)
    const [ref2, inView2] = useInView(optionsForLowHeight)

    return (
        <section className="fifth-tw-room-mob">
            <div className="fifth-tw-room-mob__wrapper">
                <div ref={ref1} style={{height:  "129.3vw"}}>
                    {inView1 &&
                        <img
                            src="TwicknhamPage/TwickenhamFifRoomLeftMob.jpg"
                            style={{width: "84vw", height: "129.3vw"}}
                            className="fifth-tw-room-mob__top opacity-animation-1_5s"
                            alt="#"
                        />
                    }
                </div>

                <div ref={ref2} className="fifth-tw-room-mob__bottom" style={{height: "134.6vw"}}>
                    {inView2 && <div className="fifth-tw-room-mob__line line opacity-animation-2s"></div>}

                    {inView2 &&
                        <img
                            className="opacity-animation-1_5s"
                            src="TwicknhamPage/TwickenhamFifRoomRightMob.jpg"
                            style={{width: "48.3vw", height: "134.6vw"}}
                            alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}