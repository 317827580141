import "../styles/burger.scss"

export const Burger = ({ navStatus, setNavStatus }) => {
    return (
        <label
            onChange={() => navStatus ? setNavStatus(false) : setNavStatus(true)}
            className="burger"
            htmlFor="check"
        >
            <input className="burger__input" type="checkbox" id="check"/>
            <span></span>
            <span></span>
            <span></span>
        </label>
    )

}