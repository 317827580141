import "../../styles/twickenhamPage.scss"
import {useInView} from "react-intersection-observer";

export const SeventhTwickenhamRoomMob = () => {

    const optionOne = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(optionOne)
    const [ref2, inView2] = useInView(optionOne)

    return (
        <section className="seventh-tw-room-mob">
            <div className="seventh-tw-room-mob__wrapper">
                <div ref={ref1} style={{height: "117.6vw"}} className="seventh-tw-room-mob__top">
                    {inView1 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "60vw", height: "57.3vw"}}
                            src="TwicknhamPage/TwickenhamSeventhRoomRightTopMob.jpg"
                            alt="#"
                        />
                    }
                    {inView1 &&
                        <img
                            className="opacity-animation-2s"
                            style={{width: "60vw", height: "57.3vw"}}
                            src="TwicknhamPage/TwickenhamSeventhRoomRightBotMob.jpg"
                            alt="#"
                        />
                    }
                </div>

                {inView1 && <div className="seventh-tw-room-mob__line line opacity-animation-3s"></div>}
                <div ref={ref2} style={{height: "112vw"}}>
                    {inView2 &&
                        <img
                            className="seventh-tw-room-mob__bottom opacity-animation-1_5s"
                            style={{width: "84vw", height: "112vw"}}
                            src="TwicknhamPage/TwickenhamSeventhRoomLeftMob.jpg"
                            alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}