import "../styles/twickenham.scss"
import {Link} from "react-router-dom";
import {useEffect} from "react";

export const TwickenhamShowcaseMob = () => {
    useEffect(() => {
        showHeader()
    }, [])

    function showHeader () {
        let h = 0;
        let mul = 1
        const twHeader = document.getElementById("tw-header")
        setInterval(() => {
            if (h >= 15) return;
            if (h >= 7) mul = 0.7
            if (h >= 12.25) mul = 0.4
            if (h >= 14.5) mul = 0.05
            h += 0.15 * mul
            twHeader.style.height = `${h}vw`;
        }, 7)
    }

    return (
        <section className="twickenham-mob">
            <img style={{width: "84vw", height: "84vw"}} loading="lazy" className="twickenham-mob__img" src="Twickenham_fph_mob.jpg"  alt="#"/>

            <div id="tw-header" className="twickenham-mob__header">
                <p>Twickenham</p>
            </div>

            <div className="twickenham-mob__ticket">
                <p className="twickenham-mob__ticket_desc">
                    To create timeless charm, we blended contemporary style with budget mindfulness.
                    Our goal<br/> was to create a space that envelops our client in a <br/>comforting ambiance.
                </p>
                <Link to='/twickenham' className="twickenham-mob__ticket_btn">
                    <p>View More</p>
                    <img src="arrow_bold.svg" alt="#"/>
                </Link>
            </div>

            <p className="twickenham__bg-txt mob">T<br/>w<br/>i<br/>c<br/>k</p>
            <p className="twickenham__bg-txt mob">enham</p>
        </section>
    )
}