import {Header, MobileHeader} from "../components";
import {useMatchMedia} from "../hooks";
import {
    FirstNottingRoom,
    EighthNottingRoom,
    FifthNottingRoom,
    FourthNottingRoom,
    SecondNottingRoom,
    SeventhNottingRoom,
    SixthNottingRoom,
    ThirdNottingRoom,
} from "../components/NottingHill";

import {useLayoutEffect} from "react";
import {useInView} from "react-intersection-observer";
import {NinthNottingRoom} from "../components/NottingHill/NinthNottingRoom";
import {TenthNottingRoom} from "../components/NottingHill/TenthNottingRoom";
import {EleventhNottingRoom} from "../components/NottingHill/EleventhNottingRoom";
import {GoForwardFooter} from "../components/GoForwardFooter";
import {
    FifthNottingRoomMob,
    FirstNottingRoomMob,
    FourthNottingRoomMob,
    SecondNottingRoomMob,
    ThirdNottingRoomMob,
    SixthNottingRoomMob,
    SeventhNottingRoomMob,
    EighthNottingRoomMob,
    TenthNottingRoomMob, EleventhNottingRoomMob
} from "../components/NottingHillMob";
import {NinthNottingRoomMob} from "../components/NottingHillMob/NinthNottingRoomMob";
import {GoForwardFooterMob} from "../components/GoForwardFooterMob";

export const NottingHillPage = () => {
    const { isMobile } = useMatchMedia()

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const optionsForHighHeight = {
        threshold: 0.05,
        triggerOnce: true
    }

    const [first, firstInView] = useInView(optionsForHighHeight)
    const [second, secondInView] = useInView(optionsForHighHeight)
    const [third, thirdInView] = useInView(optionsForHighHeight)
    const [fourth, fourthInView] = useInView(optionsForHighHeight)
    const [fifth, fifthInView] = useInView(optionsForHighHeight)
    const [sixth, sixthInView] = useInView(optionsForHighHeight)
    const [seventh, seventhInView] = useInView(optionsForHighHeight)
    const [eight, eightInView] = useInView(optionsForHighHeight)
    const [nine, nineInView] = useInView(optionsForHighHeight)
    const [ten, tenInView] = useInView(optionsForHighHeight)
    const [eleven, elevenInView] = useInView(optionsForHighHeight)

    return (
        <main>
            {isMobile ?
                <MobileHeader
                    bgName={"notting-bg"}
                    pageTitle={"Notting Hill"}
            />
                :
                <Header
                    bgName={"notting-bg"}
                    pageTitle={"Notting Hill"}
            />}
            {isMobile ?
                <section className="bg notting-page">
                    <div ref={first} style={{height: "177.3vw"}}>
                        {firstInView && <FirstNottingRoomMob/>}
                    </div>
                    <div ref={second} style={{height: "202vw"}}>
                        {secondInView && <SecondNottingRoomMob/>}
                    </div>
                    <div ref={third} style={{height: "200vw"}}>
                        {thirdInView && <ThirdNottingRoomMob/>}
                    </div>
                    <div ref={fourth} style={{height: "130vw"}}>
                        {fourthInView && <FourthNottingRoomMob/>}
                    </div>
                    <div ref={fifth} style={{height: "182vw"}}>
                        {fifthInView && <FifthNottingRoomMob/>}
                    </div>
                    <div ref={sixth} style={{height: "177vw"}}>
                        {sixthInView && <SixthNottingRoomMob/>}
                    </div>
                    <div ref={seventh} style={{height: "273.9vw"}}>
                        {seventhInView && <SeventhNottingRoomMob/>}
                    </div>
                    <div ref={eight} style={{height: "70.6vw"}}>
                        {eightInView && <EighthNottingRoomMob/>}
                    </div>
                    <div ref={nine} style={{height: "197.2vw"}}>
                        {nineInView && <NinthNottingRoomMob/>}
                    </div>
                    <div ref={ten} style={{height: "277.5vw"}}>
                        {tenInView && <TenthNottingRoomMob/>}
                    </div>
                    <div ref={eleven} style={{height: "133.2vw"}}>
                        {elevenInView && <EleventhNottingRoomMob/>}
                    </div>
                    <GoForwardFooterMob folderName="TwicknhamPage" heading="CHELSEA" link="chelsea" />
                </section>
                :
                <section className="bg notting-page">
                    <div ref={first} style={{height: "50.4vw"}}>
                        {firstInView && <FirstNottingRoom/>}
                    </div>
                    <div ref={second} style={{height: "43vw"}}>
                        {secondInView && <SecondNottingRoom/>}
                    </div>
                    <div ref={third} style={{height: "40.1vw"}}>
                        {thirdInView && <ThirdNottingRoom/>}
                    </div>
                    <div ref={fourth} style={{height: "43.2vw"}}>
                        {fourthInView && <FourthNottingRoom/>}
                    </div>
                    <div ref={fifth} style={{height: "70vw"}}>
                        {fifthInView && <FifthNottingRoom/>}
                    </div>
                    <div ref={sixth} style={{height: "50.625vw"}}>
                        {sixthInView && <SixthNottingRoom/>}
                    </div>
                    <div ref={seventh} style={{height: "83vw"}}>
                        {seventhInView && <SeventhNottingRoom/>}
                    </div>
                    <div ref={eight} style={{height: "56.35vw"}}>
                        {eightInView && <EighthNottingRoom/>}
                    </div>
                    <div ref={nine} style={{height: "57.7vw"}}>
                        {nineInView && <NinthNottingRoom/>}
                    </div>
                    <div ref={ten} style={{height: "70vw"}}>
                        {tenInView && <TenthNottingRoom/>}
                    </div>
                    <div ref={eleven} style={{height: "41.6vw"}}>
                        {elevenInView && <EleventhNottingRoom/>}
                    </div>

                    <div style={{height: "15.625vw"}}></div>

                    <GoForwardFooter folderName="TwicknhamPage" heading="Chelsea" link="chelsea" />

                    <div style={{height: "15.625vw"}}></div>
                </section>
            }

        </main>
    )
}