import "../../styles/nottingPage.scss"

export const FifthNottingRoom = () => {
    return (
        <section className="fifth-nt-room">
            <div className="fifth-nt-room__wrapper">
                <img
                    src="nottingHill/fisec-top.jpg"
                    style={{width: "80vw", height: "41.6vw"}}
                    className="fifth-nt-room__left opacity-animation-2s"
                    alt="#"
                />

                <div className="fifth-nt-room__right">
                    <div>
                        <p className="fifth-nt-room__text opacity-animation-3s">
                            ...right through to the final installation, where she and her colleague Daria worked (quite literally)
                            day and night to ensure everything was in place for our move-in date. Every detail was handled meticulously
                            and Ksenia project managed all the suppliers on our behalf, including electricians, AV specialists, decorators,
                            curtain makers and furniture suppliers.
                        </p>
                        <p className="fifth-nt-room__quote opacity-animation-3s">Client feedback</p>
                    </div>

                    <img
                        className="opacity-animation-3s"
                        src="nottingHill/fisec-bottom.jpg"
                        style={{width: "43.22vw", height: "26vw"}}
                        alt="#"
                    />
                </div>
            </div>
        </section>
    )
}
