import "../../styles/twickenhamPage.scss"

export const ThirdTwickenhamRoom = () => {

    return (
        <section className="third-tw-room photo-section">
            <div className="third-tw-room__wrapper">
                <div className="third-tw-room__left">
                    <img
                        className="opacity-animation-2s"
                        style={{width: "21.9vw", height: "18.4vw"}}
                        src="TwicknhamPage/TwickenhamTRoomLeftTop.jpg"
                        alt="#"
                        loading="lazy"
                    />
                    <img
                        className="opacity-animation-2_5s"
                        style={{width: "21.9vw", height: "18.4vw"}}
                        src="TwicknhamPage/TwickenhamTRoomLeftBott.jpg"
                        alt="#"
                        loading="lazy"
                    />
                </div>
                <div className="third-tw-room__line opacity-animation-3_5s"></div>
                <img
                    className="third-tw-room__right opacity-animation-3s"
                    style={{width: "61.6vw", height: "43.1vw"}}
                    src="TwicknhamPage/TwickenhamTRoomRight.jpg"
                    alt="#"
                    loading="lazy"
                />
            </div>
        </section>
    )
}