import "../../styles/twickenhamPage.scss"
import {useInView} from "react-intersection-observer";

export const SixthTwickenhamRoom = () => {

    const optionsForLowHeight = {
        threshold: 0.5,
        triggerOnce: true
    }

    const [secondSection, secondSectionInView] = useInView(optionsForLowHeight)

    return (
        <section className="sixth-tw-room photo-section">
            <div className="sixth-tw-room__wrapper">
                <img
                    style={{width: "72.9vw", height: "41.1vw"}}
                    className="sixth-tw-room__top-img opacity-animation-1_5s"
                    src="TwicknhamPage/TwickenhamSixRoomTop.jpg"
                    alt="#"
                />

                <div ref={secondSection} className="sixth-tw-room__bottom-block">
                    {secondSectionInView && <p className="opacity-animation-3s">
                        Inspired by the child's own desire to be an astronaut, we curated an enchanting atmosphere with
                        deep blue, starry wallpaper that sparks their imagination and creativity. We extended the
                        celestial theme by incorporating elements throughout the room that echoed the desired colour
                        palette and displayed the child’s pre-owned toys.
                    </p>
                    }
                    { secondSectionInView &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "48vw", height: "72.9vw"}}
                            src="TwicknhamPage/TwickenhamSixRoomBot.jpg"
                            alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}
