import "../../styles/nottingPage.scss"

export const NinthNottingRoom = () => (
    <section className="ninth-nt-room photo-section">
        <div className="ninth-nt-room__wrapper">
            <div className="ninth-nt-room__left">
                <img
                    className="ninth-nt-room__left_img opacity-animation-1s"
                    style={{width: "20.8vw", height: "26vw"}}
                    src="nottingHill/nisec-left-top.jpg"
                    alt="#"
                />
                <img
                    className="ninth-nt-room__left_img opacity-animation-2s"
                    style={{width: "20.8vw", height: "26vw"}}
                    src="nottingHill/nisec-left-bot.jpg"
                    alt="#"
                />
            </div>

            <div className="ninth-nt-room__right">
                <img className="opacity-animation-2_5s"
                     style={{width: "60.8vw", height: "45.6vw"}}
                     src="nottingHill/nisec-right.jpg"
                     alt="#"
                />
            </div>
            <div className="ninth-nt-room__line opacity-animation-3_5s"></div>
        </div>
    </section>
)
