import "../../styles/twickenhamPage.scss"
import {useInView} from "react-intersection-observer";

export const FourthTwickenhamRoomMob = () => {

    const optionsForLowHeight = {
        threshold: 0.2,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(optionsForLowHeight)
    const [ref2, inView2] = useInView(optionsForLowHeight)
    const [ref3, inView3] = useInView(optionsForLowHeight)

    return (
        <section className="fourth-tw-room-mob">
            <div className="fourth-tw-room-mob__wrapper">
                <div ref={ref1} style={{height: "112vw"}}>
                    {inView1 &&
                        <img
                            src="TwicknhamPage/TwickenhamFoRoomRightMob.jpg"
                            style={{width: "84vw", height: "112vw"}}
                            className="fourth-tw-room__right opacity-animation-1_5s"
                            alt="#"
                        />
                    }
                </div>

                <div ref={ref2} style={{width: "84vw", height: "83.5vw"}}>
                    {inView2 &&
                        <p className="second-tw-room-mob__top opacity-animation-1_5s">
                            In crafting this kitchen, our goal was to radiate a sense of timeless elegance and style. By
                            integrating a palette of natural hues, such as calming greens and sleek greys, we attained the
                            envisioned style of our client. What sets this project apart is our ability to combine design
                            excellence with budget consciousness.
                        </p>
                    }
                </div>

                <div ref={ref3} style={{height: "57.3vw"}}>
                    {inView3 && <div className="fourth-tw-room-mob__line line opacity-animation-2_5s"></div>}
                    {inView3 &&
                        <img
                            className="opacity-animation-2s"
                            src="TwicknhamPage/TwickenhamFoRoomLeftMob.jpg"
                            style={{width: "60vw", height: "57.3vw"}}
                            alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}