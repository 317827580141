import {useInView} from "react-intersection-observer";

export const SecondNottingRoomMob = () => {

    const optionsForLowHeight = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref2, inView2] = useInView(optionsForLowHeight)
    const [ref3, inView3] = useInView(optionsForLowHeight)

    return (
        <section className="second-nt-room-mob photo-section">
            <div className="second-nt-room-mob__wrapper">
                <div ref={ref2} style={{width: "84vw", height: "78vw"}}>
                    {inView2 &&
                        <img
                             className="second-nt-room__right opacity-animation-1_5s"
                             style={{width: "84vw", height: "78vw"}}
                             src="nottingHill/ssec-top-mob.jpg"
                             alt="#"
                        />
                    }
                </div>

                <div ref={ref3} style={{width: "60vw", height: "118.6vw"}}>
                    {inView3 &&
                        <div className="second-nt-room-mob__bottom">
                            <img
                                className="opacity-animation-1_5s"
                                style={{width: "60vw", height: "57.3vw"}}
                                src="nottingHill/ssec-bot-fir-mob.jpg"
                                alt="#"
                            />

                            <img
                                className="opacity-animation-1_5s"
                                style={{width: "60vw", height: "57.3vw"}}
                                src="nottingHill/ssec-bot-sec-mob.jpg"
                                alt="#"
                            />

                            <div className="second-nt-room-mob__line line opacity-animation-2s"></div>
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}