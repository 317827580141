import "../styles/navBar.scss"
import {Link} from "react-router-dom";
import {useEffect} from "react";

export const NavBar = () => {
    var navBar
    const navNames = ["/", "press", "about"]
    let lastScroll = 0;

    useEffect(() => {
        setNavAnimation()

        // if (navNames.includes(window.location.pathname.substring(1)) || window.location.pathname === "/") {
        //     console.log(window.location.pathname === "/" ? "home" : window.location.pathname.substring(1))
        //     let id = window.location.pathname === "/" ? "home" : window.location.pathname.substring(1)
        //     document.getElementById(id).classList.add("on-page")
        //     console.log(document.getElementById(id))
        //
        // }
    })

    function setNavAnimation () {
        navBar = document.querySelector("#navbar");
        let underNavBar = document.getElementById("under-navbar")

        window.addEventListener("scroll", () => {
            let currentScroll = window.scrollY;

            if (currentScroll > lastScroll && navBar.classList.contains("is-shown")) {
                navBar.classList.remove("is-shown")
                navBar.classList.add("is-hidden")
                underNavBar.classList.remove("open")
                underNavBar.classList.add("close")
                navBar.classList.remove("upscale-bg")
                navBar.classList.add("default-bg")
            } else if (currentScroll < lastScroll && !navBar.classList.contains("is-shown")) {
                navBar.classList.remove("is-hidden")
                navBar.classList.add("is-shown")
            }

            lastScroll = currentScroll;
        })
    }

    function openProjectList () {
        let underNavBar = document.getElementById("under-navbar")

        if (navBar.classList.contains("default-bg") && underNavBar.classList.contains("close")) {
            navBar.classList.remove("default-bg")
            navBar.classList.add("upscale-bg")

            underNavBar.classList.remove("close")
            underNavBar.classList.add("open")
        } else if (navBar.classList.contains("upscale-bg") && underNavBar.classList.contains("open")) {
            navBar.classList.add("default-bg")
            navBar.classList.remove("upscale-bg")

            underNavBar.classList.add("close")
            underNavBar.classList.remove("open")
        }
    }

    return (
        <div id="navbar" className="navbar-bg default-bg is-shown">
            <nav className="navbar">
                <li id="home"><Link to='/'>Home</Link></li>
                <li><a onClick={() => openProjectList()}>Projects</a></li>
                <li id="press"><Link to='/press'>Press</Link></li>
                <li id="about"><Link to='/about'>About Us</Link></li>
                <li><a href="/#footer">Contact</a></li>
            </nav>
            <nav id="under-navbar" className="navbar under close">
                <li><Link to='/nottinghill'>Notting Hill</Link></li>
                <li><Link to='/chelsea'>Chelsea</Link></li>
                <li><Link to='/twickenham'>Twickenham</Link></li>
            </nav>
        </div>
    )
}