import {useInView} from "react-intersection-observer";

export const ThirdChelseaRoomMob = () => {

    const options = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(options)
    const [ref2, inView2] = useInView(options)

    return (
        <section className="third-ch-room-mob">
            <div className="third-ch-room-mob__wrapper">
                <div ref={ref1} className="third-ch-room-mob__top">
                    {inView1 &&
                        <img
                            className="third-ch-room-mob__top_img opacity-animation-1_5s"
                            style={{width: "40vw", height: "51.3vw"}}
                            src="Chelsea/ThirdLeftTopMob.jpg"
                            alt="#"
                        />
                    }

                    {inView1 && <div className="third-ch-room-mob__line line opacity-animation-2_5s"></div>}

                    {inView1 &&
                        <img
                            className="third-ch-room-mob__top_img opacity-animation-1_5s"
                            style={{width: "40vw", height: "51.3vw"}}
                            src="Chelsea/ThirdLeftBotMob.jpg"
                            alt="#"
                        />
                    }
                </div>

                <div ref={ref2} className="third-ch-room-mob__bottom opacity-animation-2s">
                    {inView2 &&
                        <img
                            style={{width: "84vw", height: "112vw"}}
                             src="Chelsea/ThirdRightMob.jpg"
                             alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}