import {useInView} from "react-intersection-observer";

export const SixthChelseaRoomMob = () => {

    const options = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(options)
    const [ref2, inView2] = useInView(options)
    const [ref3, inView3] = useInView(options)

    return (
        <section className="sixth-ch-room-mob">
            <div className="sixth-ch-room-mob__wrapper">
                <div ref={ref1} style={{height: "129.3vw"}}>
                    {inView1 &&
                        <img
                            style={{width: "84vw", height: "129.3vw"}}
                            className="sixth-ch-room-mob__right opacity-animation-1_5s"
                            src="Chelsea/SixthRightMob.jpg"
                            alt="#"
                        />
                    }
                </div>

                <div ref={ref2} style={{height: "94vw"}}>
                    {inView2 &&
                        <p className="sixth-ch-room-mob__paragraph opacity-animation-2s">
                            We find immense joy in designing spaces that cater to individuals of all ages, and hold a love for
                            creating enchanting environments for children. It grants us the freedom to unleash our
                            boundless imagination, infusing our designs with a delightful touch of playfulness. In this room,
                            the carefully selected wallpapers complement the bespoke, custom made wardrobe, resulting in
                            a visually pleasing composition.
                        </p>
                    }
                </div>

                <div ref={ref3} style={{height: "66.6vw"}}>
                    {inView3 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "92vw", height: "66.6vw"}}
                            src="Chelsea/SixthLeftMob.jpg"
                            alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}