import {useMatchMedia} from "../hooks";
import {AboutPageText, Footer, MobileUtilHeader, UtilHeader} from "../components";
import "../styles/aboutPage.scss"
import {useLayoutEffect} from "react";
import {FooterMob} from "../components/FooterMob";

export const AboutUs = () => {
    const { isMobile } = useMatchMedia()

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <main>
            {isMobile ?
                <MobileUtilHeader
                    bgName={"about-bg"}
                    bgColorName={"about-page"}
                    pageTitle={"About Us"}
                />
                :
                <UtilHeader
                    bgName={"about-bg"}
                    bgColorName={"about-page"}
                    pageTitle={"About Us"}
            />}

            {isMobile ? <AboutPageText appType={"-mob"}/> : <AboutPageText appType={"-desk"}/>}

            {isMobile ? <FooterMob /> : <Footer />}

        </main>
    )
}