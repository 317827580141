import {useEffect, useState} from "react";
import {Burger} from "./Burger";
import {Link} from "react-router-dom";

export const MobileUtilHeader = ({ bgName, pageTitle }) => {
    const [navStatus, setNavStatus] = useState(false)

    useEffect(() => {
        const navBar = document.getElementById("nav_menu")
        if (navStatus) {
            navBar.classList.add("header__navbar_active")
        } else if (!navStatus) {
            navBar.classList.remove("header__navbar_active")
            document.getElementById("check").checked = false
        }
    }, [navStatus])


    let lastScroll = 0;

    useEffect(() => {
        const navBar = document.getElementById("headerNavBar");

        window.addEventListener("scroll", () => {
            let currentScroll = window.scrollY;

            if (currentScroll < 44) {
                navBar.classList.remove("header__navbar_is-hidden")
                navBar.classList.add("header__navbar_is-shown")
                return
            }

            if (currentScroll > lastScroll && navBar.classList.contains("header__navbar_is-shown")) {
                navBar.classList.remove("header__navbar_is-shown")
                navBar.classList.add("header__navbar_is-hidden")
            } else if (currentScroll < lastScroll && !navBar.classList.contains("header__navbar_is-shown")) {
                navBar.classList.remove("header__navbar_is-hidden")
                navBar.classList.add("header__navbar_is-shown")
            }

            lastScroll = currentScroll;
        })
    }, [])

    function checkIfWeAreHere (pathName) {
        window.location.pathname === pathName ? setNavStatus(false) : setNavStatus(false)
    }

    function rollUpProjectList () {
        let list = document.getElementById("project-list")

        if (list.classList.contains("hide-project-list")) {
            list.classList.remove("hide-project-list")
        } else {
            list.classList.add("hide-project-list")
        }
    }

    return (
        <header className="header-resize">
            <div id="headerNavBar" className="header__navbar header__navbar_is-shown">
                <div className="header__navbar_wrapper">
                    <Burger
                        navStatus={navStatus}
                        setNavStatus={setNavStatus}
                    />

                    <div className="header__logo">
                        <img style={{transform: "scale(0.06)"}} src="Ksenia_Design_logo_white.svg" alt="#"/>
                    </div>

                    <a className="header__contacts" href="#footer">Contacts</a>
                </div>

                <div id="nav_menu" className="header__navbar_menu header__navbar_active">
                    <nav>
                        <li><Link to='/' onClick={() => checkIfWeAreHere("/")}>Home</Link></li>
                        <li><a onClick={() => rollUpProjectList()}>Projects</a></li>
                        <ul id="project-list" className="header__navbar_project-list hide-project-list">
                            <li><Link to='/nottinghill' onClick={() => checkIfWeAreHere("/nottinghill")}>- Notting Hill</Link></li>
                            <li><Link to='/twickenham' onClick={() => checkIfWeAreHere("/twickenham")}>- Twickenham</Link></li>
                            <li><Link to='/chelsea' onClick={() => checkIfWeAreHere("/chelsea")}>- Chelsea</Link></li>
                        </ul>
                        <li><Link to='/press' onClick={() => checkIfWeAreHere("/press")}>Press</Link></li>
                        <li><Link to='/about' onClick={() => checkIfWeAreHere("/about")}>About Us</Link></li>
                        <li><a href='/#footer' onClick={() => checkIfWeAreHere("/")}>Contact</a></li>
                    </nav>
                </div>
            </div>

            <div className={`header-resize__bg ${bgName}`}></div>

            <div className="space"></div>
                <p className="header__util-txt opacity-animation-1_5s">{pageTitle}</p>
            <div></div>

        </header>
    )
}