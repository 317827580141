import "../../styles/chelseaPageMob.scss"
import {useInView} from "react-intersection-observer";

export const FirstChelseaRoomMob = () => {

    const options = {
        threshold: 0.1,
        triggerOnce: true
    }

    const txtOptions = {
        threshold: 0.4,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(options)
    const [ref2, inView2] = useInView(options)
    const [ref3, inView3] = useInView(options)
    const [ref4, inView4] = useInView(txtOptions)

    return (
        <section className="first-ch-room-mob photo-section">
            <div className="first-ch-room-mob__wrapper ">
                <div ref={ref1} className="first-ch-room-mob__top">
                    {inView1 &&
                        <img
                             className="opacity-animation-1_5s"
                             style={{width: "84vw", height: "112vw"}}
                             src="Chelsea/FirstRightMob.jpg"
                             alt="#"/>
                    }
                </div>

                <div ref={ref2} className="first-ch-room-mob__mid">
                    {inView2 &&
                        <img
                            className="first-ch-room-mob__mid_img opacity-animation-1_5s"
                            style={{width: "40vw", height: "51.3vw"}}
                            src="Chelsea/FirstLeftTopMob.jpg"
                            alt="#"
                        />
                    }

                    {inView2 &&  <div className="first-ch-room-mob__line line opacity-animation-2_5s"></div>}

                    {inView2 &&
                        <img
                            className="first-ch-room-mob__mid_img opacity-animation-2s"
                            style={{width: "40vw", height: "51.3vw"}}
                            src="Chelsea/FirstLeftBotMob.jpg"
                            alt="#"
                        />
                    }
                </div>
            </div>

            <div className="first-ch-room-mob__bottom">
                <div ref={ref3} style={{height: "66.6vw"}}>
                    {inView3 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "84vw", height: "66.6vw"}}
                            src="Chelsea/FirstBottomMob.jpg"
                            alt="#"
                        />
                    }
                </div>

                <div ref={ref4} style={{height: "74vw"}}>
                    {inView4 &&
                        <p className="opacity-animation-1_5s">
                            In this design project, we had the pleasure of working with a young family who wanted to
                            showcase their deep appreciation for their existing artwork. Our approach was to create a space
                            where these cherished pieces were displayed, while blending with the contemporary ambiance.
                        </p>
                    }
                </div>
            </div>
        </section>
    )
}