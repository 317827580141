import "../../styles/twickenhamPage.scss"

export const EighthTwickenhamRoom = () => {
    return (
        <section className="eighth-tw-room photo-section">
            <div className="eighth-tw-room__wrapper">
                <div className="eighth-tw-room__left opacity-animation-2_5s">
                    <p>
                        This room is a testament to our commitment to creating spaces that go beyond mere aesthetics.
                        By tastefully showcasing the client's beloved guitars as decorative accents, we fill the
                        atmosphere with a unique sense of character. These framed instruments cease to be mere
                        objects, transforming into a visual and tangible embodiment of the client's passion and creativity.
                    </p>
                    <img
                        style={{width: "42.4vw", height: "28.125vw"}}
                        src="TwicknhamPage/Twickenham8RoomLeft.jpg"
                        alt="#"
                    />
                </div>

                <img
                    style={{width: "35.4vw", height: "65.1vw"}}
                    className="eighth-tw-room__right opacity-animation-1_5s"
                    src="TwicknhamPage/Twickenham8RoomRight.jpg"
                    alt="#"
                />
            </div>
        </section>
    )
}