import "../styles/header.scss"
import { NavBar } from "./NavBar";
import { useEffect } from "react";

export const Header = ({ pageTitle, bgName }) => {
    var d = new Date();
    let mainTxtBg, mainTxtWrapper, mainTxtParagraph, flatArrow

    useEffect(() => {
        mainTxtBg = document.getElementById("main-text-bg")
        mainTxtWrapper = document.getElementById("main-text-wrapper")
        mainTxtParagraph = document.getElementById("main-text-paragraph")
        flatArrow = document.getElementById("flat-arrow")

        if (findCookieMatch()) {
            addStaticBlock()
        } else {
            setCookie()
            addHeaderMainTextAnimation()
        }
    }, [])

    function scrollOnWindowHeight () {
        let i = window.scrollY
        setInterval(() => {
            if (i > window.innerHeight+10) return;
            window.scrollTo(0, i)
            i += 12
        }, 1)
    }

    function addHeaderMainTextAnimation () {
        mainTxtBg.classList.add("load-main-txt")
        mainTxtWrapper.classList.add("load-main-txt-wrapper")
        mainTxtParagraph.classList.add("load-main-txt-p")
        flatArrow.classList.add("load-flat-arrow")
    }

    function addStaticBlock () {
        mainTxtBg.classList.add("loaded-main-txt")
        mainTxtWrapper.classList.add("loaded-main-txt-wrapper")
        mainTxtParagraph.classList.add("loaded-main-txt-p")
        flatArrow.classList.add("loaded-flat-arrow")
    }

    function setCookie () {
        d.setTime(d.getTime() + (1 * 10 * 60 * 1000));
        let url = window.location.pathname

        document.cookie = url + "=true;expires=" + d.toUTCString() + ";path=/;"
    }

    function findCookieMatch () {
        let cookies = document.cookie.split(" ")
        for (let i = 0; i < cookies.length; i++) {
            if (cookies[i].split("=")[0] === window.location.pathname) return true
        }
        return false
    }

    return (
        <header className="header">
            <NavBar />
            <div className={`background ${bgName}`}>
                <div className="background__blanket"></div>
            </div>

            <div id="main-text-bg" className="header__main-text">
                <div id="main-text-wrapper" className="header__main-text_wrapper">
                    <p
                        id="main-text-paragraph"
                        style={ bgName === "home-bg" ? {} : {
                            textTransform: "uppercase",
                            letterSpacing: "0.1em",
                            fontSize: "8.5vw"
                        }}
                    >{pageTitle}</p>
                </div>
            </div>

            <img
                onClick={() => scrollOnWindowHeight()}
                id="flat-arrow"
                className="header__arrow"
                src="flat_arrow.svg"
                height="125px"
                width="125px"
                alt="#"
            />
        </header>
    )
}