import {useInView} from "react-intersection-observer";
import "../../styles/nottingHillPageMob.scss"

export const EleventhNottingRoomMob = () => {

    const optionsForLowHeight = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref2, inView2] = useInView(optionsForLowHeight)
    const [ref3, inView3] = useInView(optionsForLowHeight)

    return (
        <section className="eleventh-nt-room-mob">
            <div className="eleventh-nt-room-mob__wrapper">
                <div ref={ref2} style={{width: "58.6vw", height: "58.6vw"}}>
                    {inView2 &&
                        <div className="eleventh-nt-room-mob__bottom">
                            <img
                                className="opacity-animation-1_5s"
                                style={{width: "58.6vw", height: "58.6vw"}}
                                src="nottingHill/elsec-top-mob.jpg"
                                alt="#"
                            />
                            <div className="eleventh-nt-room-mob__line line opacity-animation-2s"></div>
                        </div>
                    }
                </div>

                <div ref={ref3} style={{width: "92vw", height: "70.6vw"}}>
                    {inView3 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "92vw", height: "70.6vw"}}
                            src="nottingHill/elsec-bottom-mob.jpg"
                            alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}