import "../../styles/twickenhamPage.scss"

export const FifthChelseaRoom = () => {
    return (
        <section className="fifth-ch-room photo-section">
            <div className="fifth-ch-room__wrapper wrapper">
                <img
                    className="fifth-ch-room__left opacity-animation-2_5s"
                    style={{width: "42.3vw", height: "65.1vw"}}
                    src="Chelsea/FifthLeft_light.jpg"
                    alt="#"
                />

                <div className="fifth-ch-room__right">
                    <img
                        style={{width: "22.4vw", height: "26vw"}}
                        className="fifth-ch-room__right opacity-animation-1_5s"
                        src="Chelsea/FifthRightTop_light.jpg"
                        alt="#"
                    />
                    <div className="fifth-ch-room__line line opacity-animation-3s"></div>
                    <img
                        style={{width: "22.4vw", height: "26vw"}}
                        className="fifth-ch-room__right opacity-animation-2s"
                        src="Chelsea/FifthRightBot_light.jpg"
                        alt="#"
                    />
                </div>
            </div>
        </section>
    )
}