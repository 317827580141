import { useState, useLayoutEffect } from "react";

const queries = [
    '(max-width: 766px)',
    '(min-width: 767px) and (max-width: 1199px)',
    '(min-width: 1200x)',
];

export const useMatchMedia = () => {
    const mediaQueryLists = queries.map(query => matchMedia(query));

    const getValues = () => mediaQueryLists.map(mqOne => mqOne.matches);

    const [value, setValues] = useState(getValues);

    useLayoutEffect(() => {
        const handler = () => setValues(getValues);

        mediaQueryLists.forEach(mqOne => mqOne.addEventListener('change', handler));

        return () => mediaQueryLists.forEach(mqOne => mqOne.removeEventListener('change', handler));
    })

    return ['isMobile', 'isTablet', 'isDesktop'].reduce((acc, screen, index) => ({
        ...acc,
        [screen]: value[index]
    }), {})
}