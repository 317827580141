import "../styles/about.scss"
import {Link} from "react-router-dom";
import {useEffect} from "react";

const  About = () => {
    useEffect(() => {
        animateBg()
    }, [])

    function animateBg () {
        let w = 0
        let mul = 0.7
        const aboutBg = document.getElementById("about-bg")

        setInterval(() => {
            if (w >= 100) return
            if (w >= 75) mul = 0.5
            if (w >= 97) mul = 0.1
            if (w >= 99) mul = 0.04
            w += mul
            aboutBg.style.width = `${w}%`
        }, 7)
    }

    return (
        <section className="about">
            <div id="about-bg" className="about-whole-wrapper"></div>
            <div className="about__wrapper">
                <div className="about__name">
                    <p>Ksenia</p>
                    <img src="curved-line.svg" alt="#" className="about__name_hr" />
                    <p>Timoshenko</p>
                </div>

                <Link to="/about" className="about__name_btn">
                    <p>View More About</p>
                    <img src="arrow_bold.svg" alt="#"/>
                </Link>
            </div>

            <img className="about__portrait" width="35vw" src="team_photo_optimized-home.jpg" alt="#"/>

            <Link to="/about" className="about__arrow_bg">
                <i className="about__arrow"></i>
            </Link>
        </section>
    )
}

export default About;