import './App.scss';
import { Routes, Route} from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import {AboutUs} from "./pages/AboutUs";
import {TwickenhamPage} from "./pages/TwickenhamPage";
import {ChelseaPage} from "./pages/ChelseaPage";
import {PressPage} from "./pages/PressPage";
import {NottingHillPage} from "./pages/NottingHillPage";

function App() {
  return (
      <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/nottinghill' element={<NottingHillPage />} />
          <Route path='/twickenham' element={<TwickenhamPage />} />
          <Route path='/chelsea' element={<ChelseaPage />} />
          <Route path='/press' element={<PressPage />} />
          <Route path='/about' element={<AboutUs />} />
      </Routes>
  );
}

export default App;
