import "../styles/twickenham.scss"
import {useEffect} from "react";
import {Link} from "react-router-dom";

const TwickenhamShowcase = () => {
    useEffect(() => {
        showHeader()
    })

    function showHeader () {
        let h = 0;
        let mul = 1
        const twHeader = document.getElementById("tw-header")
        setInterval(() => {
            if (h >= 8.5) return;
            if (h >= 3.5) mul = 0.7
            if (h >= 6.5) mul = 0.4
            if (h >= 8) mul = 0.05
            h += 0.12 * mul
            twHeader.style.height = `${h}vw`;
        }, 7)
    }

    return (
        <section className="twickenham">
            <div id="tw-header" className="twickenham__header opacity-loading-1s">
                <p id="tw-header-txt" className="load-header-text-opacity ">Twickenham</p>
            </div>

            <div className="twickenham__container">
                <div id="tw-ticket-wrapper" className="twickenham__wrapper opacity-loading-1s">
                    <div id="tw-ticket" className="twickenham__ticket header-opacity-2s">
                        <div className="twickenham__blocker"></div>

                        <div className="twickenham__ticket_desc">
                            <p>
                                To create timeless charm, we blended contemporary style with budget mindfulness.
                                Our goal was to create a space that envelops our client in a comforting ambiance.
                            </p>
                        </div>

                        <Link to="/twickenham" className="twickenham__ticket_btn">
                            <p>View more</p>
                            <img src="arrow_bold.svg" alt="#"/>
                        </Link>
                    </div>
                </div>

                <div id="tw-images" className="twickenham__imgs">
                    <img id="first-img" className="twickenham__imgs_one header-opacity-1_7s" src="Twickenham_fph_heavy.jpg" loading="lazy" height="29.9vw" width="19.53vw" alt="#"/>
                    <div className="twickenham__imgs_wrapper">
                        <img id="second-img" className="twickenham__imgs_two header-opacity-2_5s" src="Twickenham_sph_heavy.jpg" loading="lazy" height="14.6vw" width="19.53vw" alt="#"/>
                        <img id="third-img" className="twickenham__imgs_three header-opacity-3_3s" src="Twickenham_thph_heavy.jpg" loading="lazy" height="14.6vw" width="19.53vw" alt="#"/>
                    </div>
                </div>

                <Link to="/twickenham" id="arrow-block" className="twickenham__arrow arrow-from-right-to-left">
                    <i className="twickenham__arrow_right"></i>
                </Link>
            </div>

            <p id="bg-txt" className="twickenham__bg-txt">Twick</p>
            <p id="bg-txt" className="twickenham__bg-txt">enham</p>
        </section>
    )
}

export default TwickenhamShowcase;