import {Link} from "react-router-dom";
import "../styles/footer.scss"

export const GetBackFooter = ({folderName, heading, link}) => {
    return (
        <footer className="gb-footer">
            <Link to={`/${link}`} className="gb-footer__wrapper">
                <p>{heading}</p>
                <p>View More</p>
                <img src={`${folderName}/Footer.jpg`} alt="#"/>
            </Link>

            <img className="gb-footer__arrow" src="arrow_bold.svg" alt="#"/>
        </footer>
    )
}