export const SecondTwickenhamRoom = () => {
    return (
        <section className="second-tw-room photo-section">
            <div className="second-tw-room__wrapper">
                <img
                    className="opacity-animation-2s"
                    style={{width: "42.4vw", height: "27.8vw"}}
                    src="TwicknhamPage/TwickenhamSRoomLeft.jpg"
                    alt="#"
                    loading="lazy"
                />

                <img
                    className="second-tw-room__right opacity-animation-3s"
                    style={{width: "36vw", height: "48vw"}}
                    src="TwicknhamPage/TwickenhamSRoomRight.jpg"
                    alt="#"
                    loading="lazy"
                />
            </div>
        </section>
    )
}