import "../../styles/twickenhamPage.scss"

export const FourthTwickenhamRoom = () => {

    return (
        <section className="fourth-tw-room photo-section">
            <div className="fourth-tw-room__wrapper">
                <div className="fourth-tw-room__left">
                    <img
                        className="opacity-animation-2s"
                        src="TwicknhamPage/TwickenhamFoRoomLeft.jpg"
                        style={{width: "23.4vw", height: "22.4vw"}}
                        alt="#"
                    />

                    <p className="opacity-animation-2_5s">
                        In crafting this kitchen, our goal was to radiate a sense of timeless elegance and style. By
                        integrating a palette of natural hues, such as calming greens and sleek greys, we attained the
                        envisioned style of our client. What sets this project apart is our ability to combine design
                        excellence with budget consciousness.
                    </p>
                </div>


                <img
                    src="TwicknhamPage/TwickenhamFoRoomRight.jpg"
                    style={{width: "48.1vw", height: "70vw"}}
                    className="fourth-tw-room__right opacity-animation-3s"
                    alt="#"
                />

                <div className="fourth-tw-room__line opacity-animation-3_5s"></div>
            </div>
        </section>
    )
}