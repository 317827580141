import "../../styles/twickenhamPage.scss"
import {useInView} from "react-intersection-observer";

export const FourthChelseaRoom = () => {

    const optionsForHighHeight = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref, InView] = useInView(optionsForHighHeight)

    return (
        <section className="fourth-ch-room photo-section">
            <img
                style={{width: "80vw", height: "42vw"}}
                className="fourth-ch-room__top opacity-animation-1_5s"
                src="Chelsea/FourthTop_light.jpg"
                alt="#"
            />

            <div ref={ref} className="fourth-ch-room__wrapper photo-section">
                {InView &&
                    <div className="fourth-ch-room__left">
                        <div className="fourth-ch-room__line line opacity-animation-3s"></div>
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "14.7vw", height: "19.375vw"}}
                            src="Chelsea/FourthLeft1_light.jpg"
                            alt="#"
                        />
                        <img
                            className="opacity-animation-2s"
                            style={{width: "14.7vw", height: "19.375vw"}}
                            src="Chelsea/FourthLeft2_light.jpg"
                            alt="#"
                        />
                        <img
                            className="opacity-animation-2_5s"
                            style={{width: "14.7vw", height: "19.375vw"}}
                            src="Chelsea/FourthLeft3_light.jpg"
                            alt="#"
                        />
                    </div>
                }

                {InView &&
                    <img
                        style={{width: "62vw", height: "52vw"}}
                        className="fourth-ch-room__right opacity-animation-3s"
                        src="Chelsea/FourthRight_light.jpg"
                        alt="#"
                    />
                }
            </div>
        </section>
    )
}