import "../../styles/nottingHillPageMob.scss"
import {useInView} from "react-intersection-observer";

export const FirstNottingRoomMob = () => {

    const optionsForLowHeight = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(optionsForLowHeight)
    const [ref2, inView2] = useInView(optionsForLowHeight)

    return (
        <section className="first-nt-room-mob photo-section">
            <div className="first-nt-room-mob__wrapper">
                <div ref={ref1} className="first-nt-room-mob__top">
                    {inView1 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "84vw", height: "112vw"}}
                            src="nottingHill/fsec-top-mob.jpg"
                            alt="#"
                        />
                    }
                </div>
                <div ref={ref2} className="first-nt-room-mob__bottom">
                    {inView2 &&
                        <img
                            className="first-nt-room-mob__bottom_img opacity-animation-1_5s"
                            style={{width: "40vw", height: "51.3vw"}}
                            src="nottingHill/fsec-bot-fir-mob.jpg"
                            alt="#"
                        />
                    }

                    {inView2 && <div className="first-nt-room-mob__line line opacity-animation-2s"></div>}

                    {inView2 &&
                        <img
                            className="first-nt-room-mob__bottom_img opacity-animation-1_5s"
                            style={{width: "40vw", height: "51.3vw"}}
                            src="nottingHill/fsec-bot-sec-mob.jpg"
                            alt="#"
                        />}
                </div>
            </div>
        </section>
    )
}