import "../../styles/nottingPage.scss"

export const FourthNottingRoom = () => (
    <section className="fourth-nt-room photo-section">
        <div className="fourth-nt-room__wrapper">
            <div className="fourth-nt-room__left">
                <img className="opacity-animation-1_5s"
                     style={{width: "55vw", height: "41.6vw"}}
                     src="nottingHill/frsec-left.jpg"
                     alt="#"
                />
            </div>

            <div className="fourth-nt-room__right">
                <img
                    className="fourth-nt-room__right_img opacity-animation-2s"
                    style={{width: "23vw", height: "21.875vw"}}
                    src="nottingHill/frsec-right-first.jpg"
                    alt="#"
                />
                <img
                    className="fourth-nt-room__right_img opacity-animation-3s"
                    style={{width: "23vw", height: "21.875vw"}}
                    src="nottingHill/frsec-right-second.jpg"
                    alt="#"
                />
            </div>
            <div className="fourth-nt-room__line opacity-animation-3_5s"></div>
        </div>
    </section>
)
