import "../../styles/nottingHillPageMob.scss"
import {useInView} from "react-intersection-observer";

export const SixthNottingRoomMob = () => {
    const options = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(options)
    const [ref2, inView2] = useInView(options)

    return (
        <section className="sixth-nt-room-mob">
            <div className="sixth-nt-room-mob__wrapper ">
                <div ref={ref1} style={{height: "64vw"}}>
                    {inView1 &&
                        <img
                            style={{width: "92vw", height: "64vw"}}
                            className="sixth-nt-room-mob__top opacity-animation-1_5s"
                            src="nottingHill/sixsec-top-mob.jpg"
                            alt="#"
                        />
                    }
                </div>

                <div ref={ref2} className="sixth-nt-room-mob__bottom">
                    {inView2 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "48.6vw", height: "50.6vw"}}
                            src="nottingHill/sixsec-bottom-fir-mob.jpg"
                            alt="#"
                        />
                    }
                    {inView2 && <div className="sixth-nt-room-mob__line line opacity-animation-2_5s"></div>}
                    {inView2 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "48.6vw", height: "50.6vw"}}
                            src="nottingHill/sixsec-bottom-sec-mob.jpg"
                            alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}