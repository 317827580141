import "../../styles/nottingPage.scss"

export const SeventhNottingRoom = () => {
    return (
        <section className="seventh-nt-room photo-section">
            <div className="seventh-nt-room__wrapper">
                <div className="seventh-nt-room__top">
                    <img
                        style={{width: "66vw", height: "52vw"}}
                        className="seventh-nt-room__left opacity-animation-2s"
                        src="nottingHill/sesec-top-left.jpg"
                        alt="#"
                    />

                    <div className="seventh-nt-room__right">
                        <img
                            className="opacity-animation-2_5s"
                            style={{width: "19.5vw", height: "61vw"}}
                            src="nottingHill/sesec-top-right.jpg"
                            alt="#"
                        />
                        <div className="seventh-nt-room__line opacity-animation-3s"></div>
                    </div>
                </div>

                <div className="seventh-nt-room__bottom">
                    <img
                        className="opacity-animation-3s"
                        style={{width: "19.5vw", height: "22.4vw"}}
                        src="nottingHill/sesec-bot-left.jpg"
                        alt="#"
                    />
                    <img
                        className="opacity-animation-3s"
                        style={{width: "19.5vw", height: "22.4vw"}}
                        src="nottingHill/sesec-bot-right.jpg"
                        alt="#"
                    />
                </div>
            </div>
        </section>
    )
}