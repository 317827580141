import "../../styles/chelseaPage.scss"
import {useInView} from "react-intersection-observer";

export const SecondChelseaRoom = () => {

    const optionsForHighHeight = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref, InView] = useInView(optionsForHighHeight)

    return (
        <section className="second-ch-room photo-section">
            <div className="wrapper">
                <img
                    style={{width: "100%", height: "47vw"}}
                    className="second-ch-room__top opacity-animation-1_5s"
                    src="Chelsea/SecondTop_light.jpg"
                    alt="#"
                />
            </div>

            <div ref={ref} className="second-ch-room__wrapper wrapper photo-section">
                {InView &&
                    <img
                        className="second-ch-room__left opacity-animation-1_5s"
                        style={{width: "35.4vw", height: "65.2vw"}}
                        src="Chelsea/SecondLeft_light.jpg"
                        alt="#"
                    />
                }

                {InView &&
                    <div className="second-ch-room__right opacity-animation-3s">
                        <p>
                            In this captivating dining room, the design of the table is inviting and artfully reflecting the marble
                            countertops in the neighboring kitchen. Guided by our client's taste for a distinct and refined
                            aesthetic, we meticulously curated a sophisticated, look that sets the stage for
                            unforgettable gatherings while seamlessly enhancing the contemporary theme that flows
                            throughout the entire house.
                        </p>
                        <img
                            style={{width: "42.4vw", height: "27.8vw"}}
                            src="Chelsea/SecondRight_light.jpg"
                            alt="#"
                        />
                    </div>
                }
            </div>
        </section>
    )
}
