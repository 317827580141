import {useInView} from "react-intersection-observer";

export const SecondTwickenhamRoomMob = () => {

    const optionsForLowHeight = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref2, inView2] = useInView(optionsForLowHeight)
    const [ref3, inView3] = useInView(optionsForLowHeight)

    return (
        <section className="second-tw-room-mob">
            <div className="second-tw-room-mob__wrapper">
                <div className="second-tw-room-mob__bottom">
                    <div ref={ref2} style={{width: "84vw", height: "112vw"}}>
                        {inView2 &&
                            <img
                                 className="second-tw-room__right opacity-animation-1_5s"
                                 style={{width: "84vw", height: "112vw"}}
                                 src="TwicknhamPage/TwickenhamSRoomRightMob.jpg"
                                 alt="#"
                            />
                        }
                    </div>

                    <div ref={ref3} style={{width: "84vw", height: "56vw"}}>
                        {inView3 &&
                            <img
                                className="opacity-animation-1_5s"
                                style={{width: "84vw", height: "56vw"}}
                                src="TwicknhamPage/TwickenhamSRoomLeftMob.jpg"
                                alt="#"
                            />
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}