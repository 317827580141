import {Header, MobileHeader} from "../components";
import {useMatchMedia} from "../hooks";
import {
    EighthTwickenhamRoom,
    FifthTwickenhamRoom,
    FirstTwickenhamRoom,
    FourthTwickenhamRoom,
    SecondTwickenhamRoom,
    SeventhTwickenhamRoom,
    SixthTwickenhamRoom,
    ThirdTwickenhamRoom,
} from "../components/Twickenham";

import {
    EighthTwickenhamRoomMob,
    FifthTwickenhamRoomMob,
    FirstTwickenhamRoomMob,
    FourthTwickenhamRoomMob,
    SecondTwickenhamRoomMob,
    SeventhTwickenhamRoomMob,
    SixthTwickenhamRoomMob,
    ThirdTwickenhamRoomMob,
} from "../components/TwickenhamMob";
import {useLayoutEffect} from "react";
import {useInView} from "react-intersection-observer";
import {GetBackFooter} from "../components/GetBackFooter";
import {GetBackFooterMob} from "../components/GetBackFooterMob";

export const TwickenhamPage = () => {
    const { isMobile } = useMatchMedia()

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const optionsForHighHeight = {
        threshold: 0.05,
        triggerOnce: true
    }

    const [first, firstInView] = useInView(optionsForHighHeight)
    const [second, secondInView] = useInView(optionsForHighHeight)
    const [third, thirdInView] = useInView(optionsForHighHeight)
    const [fourth, fourthInView] = useInView(optionsForHighHeight)
    const [fifth, fifthInView] = useInView(optionsForHighHeight)
    const [sixth, sixthInView] = useInView(optionsForHighHeight)
    const [seventh, seventhInView] = useInView(optionsForHighHeight)
    const [eight, eightInView] = useInView(optionsForHighHeight)

    return (
        <main>
            {isMobile ?
                <MobileHeader
                    bgName={"twick-bg"}
                    pageTitle={"Twickenham"}
            />
                :
                <Header
                    bgName={"twick-bg"}
                    pageTitle={"Twickenham"}
            />}
            {isMobile ?
                <section className="bg twickenham-page">
                    <div ref={first} style={{height: "177.3vw"}}>
                        {firstInView && <FirstTwickenhamRoomMob/>}
                    </div>
                    <div ref={second} style={{height: "172.4vw"}}>
                        {secondInView && <SecondTwickenhamRoomMob/>}
                    </div>
                    <div ref={third} style={{height: "190.6vw"}}>
                        {thirdInView && <ThirdTwickenhamRoomMob/>}
                    </div>
                    <div ref={fourth} style={{height: "252vw"}}>
                        {fourthInView && <FourthTwickenhamRoomMob/>}
                    </div>
                    <div ref={fifth} style={{height: "269.3vw"}}>
                        {fifthInView && <FifthTwickenhamRoomMob/>}
                    </div>
                    <div ref={sixth} style={{height: "275.9vw"}}>
                        {sixthInView && <SixthTwickenhamRoomMob/>}
                    </div>
                    <div ref={seventh} style={{height: "236vw"}}>
                        {seventhInView && <SeventhTwickenhamRoomMob/>}
                    </div>
                    <div ref={eight} style={{height: "322.6vw"}}>
                        {eightInView && <EighthTwickenhamRoomMob/>}
                    </div>
                    <GetBackFooterMob folderName="TwicknhamPage" heading="CHELSEA" link="chelsea" />
                    <div style={{height: "14.6vw"}}></div>
                </section>
                :
                <section className="bg twickenham-page">
                    <div ref={first} style={{height: "45.05vw"}}>
                        {firstInView && <FirstTwickenhamRoom/>}
                    </div>
                    <div ref={second} style={{height: "46.875vw"}}>
                        {secondInView && <SecondTwickenhamRoom/>}
                    </div>
                    <div ref={third} style={{height: "43.22vw"}}>
                        {thirdInView && <ThirdTwickenhamRoom/>}
                    </div>
                    <div ref={fourth} style={{height: "70.05vw"}}>
                        {fourthInView && <FourthTwickenhamRoom/>}
                    </div>
                    <div ref={fifth} style={{height: "65.10vw"}}>
                        {fifthInView && <FifthTwickenhamRoom/>}
                    </div>
                    <div ref={sixth} style={{height: "121.09vw"}}>
                        {sixthInView && <SixthTwickenhamRoom/>}
                    </div>
                    <div ref={seventh} style={{height: "71.875vw"}}>
                        {seventhInView && <SeventhTwickenhamRoom/>}
                    </div>
                    <div ref={eight} style={{height: "65.36vw"}}>
                        {eightInView && <EighthTwickenhamRoom/>}
                    </div>

                    <div style={{height: "15.625vw"}}></div>

                    <GetBackFooter folderName="TwicknhamPage" heading="Chelsea" link="chelsea" />

                    <div style={{height: "15.625vw"}}></div>
                </section>
            }

        </main>
    )
}