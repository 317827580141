import "../../styles/twickenhamPage.scss"
import {useInView} from "react-intersection-observer";

export const FourthChelseaRoomMob = () => {

    const options = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(options)
    const [ref2, inView2] = useInView(options)
    const [ref3, inView3] = useInView(options)

    return (
        <section className="fourth-ch-room-mob">
            <div className="fourth-ch-room-mob__wrapper">
                <div ref={ref1} style={{height: "66.6vw"}}>
                    {inView1 &&
                        <img
                            style={{width: "92vw", height: "66.6vw"}}
                            className="fourth-ch-room__top"
                            src="Chelsea/FourthTopMob.jpg"
                            alt="#"
                        />
                    }
                </div>

                <div ref={ref2} className="fourth-ch-room-mob__mid">
                    {inView2 && <div className="fourth-ch-room-mob__line line opacity-animation-3s"></div>}

                    {inView2 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "38vw", height: "50vw"}}
                            src="Chelsea/FourthLeft1Mob.jpg"
                            alt="#"
                        />
                    }

                    {inView2 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "38vw", height: "50vw"}}
                            src="Chelsea/FourthLeft2Mob.jpg"
                            alt="#"
                        />
                    }

                    {inView2 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "38vw", height: "50vw"}}
                            src="Chelsea/FourthLeft3Mob.jpg"
                            alt="#"
                        />
                    }
                </div>

                <div ref={ref3} style={{height: "66.6vw"}}>
                    {inView3 &&
                        <img
                            style={{width: "92vw", height: "66.6vw"}}
                            className="fourth-ch-room-mob__bottom opacity-animation-1_5s"
                            src="Chelsea/FourthRightMob.jpg"
                            alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}