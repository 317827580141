export const AboutPageText = ({ appType }) => {
    return (
        <section className="bg about-page">
            <div className={"about-page__text" + appType}>
                <h2>About Us:</h2>
                <p>
                    Welcome to Ksenia Designs, where sleek sophistication meets nature-inspired minimalism. We
                    believe in creating spaces that reflect your personal preference and draw inspiration from the
                    unique surroundings of your location. Our talented team combines contemporary aesthetics with
                    the beauty of the natural world, curating furniture, decor, and finishes that embody simplicity and
                    elegance. With a focus on a calming colour palette, Ksenia Designs enhances your space to
                    sustainably provide a comforting living environment to call home.
                </p>
            </div>

            <img style={{width: "95vw", height: "68.75vw"}} src="About/About-main.jpg" alt="#"/>

            <div className={"about-page__text" + appType}>
                <h2>Approach:</h2>
                <p>
                    As well as creating tailor-made environments for our clients, Ksenia Designs works with
                    eco-friendly suppliers, providing the best quality, long-lasting furniture selection that reduces
                    waste and promotes sustainability. We also prioritize individual financial considerations, working
                    closely with clients to understand their goals, preferences, and budgets. Additionally, we
                    embrace the use of existing, pre-loved furniture to breathe new life into cherished pieces and
                    minimize our environmental impact.
                </p>
            </div>

            {appType === "-desk" ?
                <img style={{width: "66.66vw", height: "49.50vw"}} src="team_photo_optimized.jpg" alt="#"/> :
                <img style={{width: "84vw", height: "64vw"}} src="team_photo_mob_optimized.jpg" alt="#"/>
            }

            <div className={"about-page__text" + appType}>
                <h2>Services:</h2>
                <p>
                    We offer a range of services to cater to the unique needs and preferences of our clients. With
                    our full-scale service, we take full control on project management, handling every detail from
                    conceptualization to final installation. For those who are more budget conscious, our
                    collaborative service provides a plethora of ideas, inspiring clients to shop themselves while
                    benefiting from our guidance. Additionally, our consultation service offers valuable insights,
                    recommendations, and expert tips on a daily rate basis to help clients transform their living
                    environment into their dream home.
                </p>
            </div>
        </section>
    )
}