import "../../styles/twickenhamPage.scss"
import {useInView} from "react-intersection-observer";

export const ThirdTwickenhamRoomMob = () => {

    const optionsForLowHeight = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(optionsForLowHeight)
    const [ref2, inView2] = useInView(optionsForLowHeight)

    return (
        <section className="third-tw-room-mob">
            <div className="third-tw-room-mob__wrapper">
                <div ref={ref1} className="third-tw-room-mob__top">
                    {inView1 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "60vw", height: "57.3vw"}}
                            src="TwicknhamPage/TwickenhamTRoomLeftTopMob.jpg"
                            alt="#"
                        />
                    }
                    {inView1 &&
                        <img
                            className="opacity-animation-1_5s"
                            style={{width: "60vw", height: "57.3vw"}}
                            src="TwicknhamPage/TwickenhamTRoomLeftBottMob.jpg"
                            alt="#"
                        />
                    }
                </div>
                {inView1 && <div className="third-tw-room-mob__line line opacity-animation-2s"></div>}

                <div ref={ref2} style={{height: "66.6vw"}}>
                    {inView2 &&
                        <img
                            className="third-tw-room-mob__bottom opacity-animation-2_5s"
                            style={{width: "92vw", height: "66.6vw"}}
                            src="TwicknhamPage/TwickenhamTRoomRightMob.jpg"
                            alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}