import {useMatchMedia} from "../hooks";
import {Footer, MobileUtilHeader, UtilHeader} from "../components";
import "../styles/pressPage.scss"
import {useLayoutEffect} from "react";
import {FooterMob} from "../components/FooterMob";

export const PressPage = () => {
    const { isMobile } = useMatchMedia()

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <main>
            {isMobile ?
                <MobileUtilHeader
                    bgName={"press-bg"}
                    bgColorName={"press-page"}
                    pageTitle={"Press"}
                />
                :
                <UtilHeader
                    bgName={"press-bg"}
                    bgColorName={"press-page"}
                    pageTitle={"Press"}
            />}

            <section className="bg press-page">
                <div className="press-page__first">
                    <img style={{width: "40.85vw", height: "56.17vw"}} src="Press/FirstLeft-desk.png" alt="#"/>
                    <div className="press-page__stack">
                        <img style={{width: "28.125vw", height: "39.29vw"}} src="Press/Cover.jpg" alt="#"/>
                        <img style={{width: "39.29vw", height: "28.125vw"}} src="Press/ChelseaMain-desk.png" alt="#"/>
                        <img style={{width: "39.29vw", height: "28.125vw"}} src="Press/Room-desk.png" alt="#"/>
                    </div>
                </div>

                <img className="press-page__second" style={{width: "80.2vw", height: "56.17vw"}} src="Press/ChelseaMain-desk.png" alt="#"/>
                <img className="press-page__third" style={{width: "80.2vw", height: "56.17vw"}} src="Press/Room-desk.png" alt="#"/>

                <div className="press-page__last">
                    <div className="press-page__last_text">
                        <p>25</p>
                        <strong>February</strong>
                        <p>2023</p>
                    </div>
                    <img style={{width: "40.85vw", height: "56.17vw"}} src="Press/LastRight-desk.png" alt="#"/>
                </div>

            </section>

            {isMobile ? <FooterMob /> : <Footer />}
        </main>
    )
}