import "../../styles/nottingHillPageMob.scss"
import {useInView} from "react-intersection-observer";

export const ThirdNottingRoomMob = () => {


    const optionsForLowHeight = {
        threshold: 0.2,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(optionsForLowHeight)
    const [ref2, inView2] = useInView(optionsForLowHeight)
    const [ref3, inView3] = useInView(optionsForLowHeight)

    return (
        <section className="third-nt-room-mob">
            <div className="third-nt-room-mob__wrapper">
                <div ref={ref1} style={{width: "84vw"}}>
                    {inView1 &&
                       <div className="third-nt-room-mob__top">
                           <p className="opacity-animation-1_5s">
                               Ksenia Design were totally invaluable when it came to our large house redesign project.
                               From the initial consultation, where Ksenia prepared a visual quiz for Tom and I to choose which interior images attracted
                               us so she could gauge our tastes...
                           </p>
                           <p className="third-nt-room-mob__quote opacity-animation-2s">Client feedback</p>
                       </div>
                    }
                </div>

                <div ref={ref2} style={{height: "66.66vw"}}>
                    {inView2 &&
                        <img
                            src="nottingHill/tsec-top-mob.jpg"
                            style={{width: "92vw", height: "66.66vw"}}
                            className="opacity-animation-1_5s"
                            alt="#"
                        />
                    }
                </div>


                <div ref={ref3} style={{height: "64.6vw"}}>
                    {inView3 &&
                        <img
                            className="third-nt-room-mob__bottom opacity-animation-2s"
                            src="nottingHill/tsec-bot-mob.jpg"
                            style={{width: "84vw", height: "64.6vw"}}
                            alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}