import "../../styles/nottingPage.scss"

export const TenthNottingRoom = () => (
    <section className="tenth-nt-room photo-section">
        <div className="tenth-nt-room__wrapper">
            <img
                style={{width: "43.5vw", height: "70vw"}}
                className="tenth-nt-room__left opacity-animation-1_5s"
                src="nottingHill/tensec-left.jpg"
                alt="#"
            />

            <div className="tenth-nt-room__right opacity-animation-2_5s">
                <div>
                    <p className="tenth-nt-room__text">
                        The concepts Ksenia produced matched our brief perfectly and she was always open to further suggestions
                        or requests, even after we moved in and realized we wanted to add a few things. We would have never been able
                        to design, manage and execute a project of this scale so beautifully and are so glad we had K|D to help us.
                    </p>
                    <p className="tenth-nt-room__quote">Client feedback</p>
                </div>

                <img
                    style={{width: "37.76vw", height: "30.46vw"}}
                    src="nottingHill/tensec-right.jpg"
                    alt="#"
                />
            </div>
        </div>
    </section>
)
