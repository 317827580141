export const SecondNottingRoom = () => {
    return (
        <section className="second-nt-room">
            <div className="second-nt-room__wrapper">
                <div className="second-nt-room__left">
                    <img
                        className="opacity-animation-2s"
                        style={{width: "20.8vw", height: "20.8vw"}}
                        src="nottingHill/ssec-left-fir.jpg"
                        alt="#"
                        loading="lazy"
                    />

                    <img
                        className="opacity-animation-2s"
                        style={{width: "20.8vw", height: "20.8vw"}}
                        src="nottingHill/ssec-left-sec.jpg"
                        alt="#"
                        loading="lazy"
                    />
                </div>

                <div className="second-nt-room__line opacity-animation-3_5s"></div>

                <img
                    className="second-nt-room__right opacity-animation-3s"
                    style={{width: "48.4vw", height: "39.6vw"}}
                    src="nottingHill/ssec-right.jpg"
                    alt="#"
                    loading="lazy"
                />
            </div>
        </section>
    )
}