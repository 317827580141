import {Link} from "react-router-dom";
import { Burger } from "./Burger";
import {useEffect, useState} from "react";

export const MobileHeader = ({ pageTitle, bgName }) => {
    const [navStatus, setNavStatus] = useState(false)
    var d = new Date();
    let mainTxtBg, mainTxtWrapper, mainTxtParagraph

    useEffect(() => {
        mainTxtBg = document.getElementById("main-text-bg")
        mainTxtWrapper = document.getElementById("main-text-wrapper")
        mainTxtParagraph = document.getElementById("main-text-paragraph")

        if (findCookieMach()) {
            addStaticBlock()
        } else {
            setCookie()
            addHeaderMainTextAnimation()
        }
    }, [])

    function addHeaderMainTextAnimation () {
        mainTxtBg.classList.add("load-main-txt")
        mainTxtWrapper.classList.add("load-main-txt-wrapper")
        mainTxtParagraph.classList.add("load-main-txt-p")
    }

    function addStaticBlock () {
        mainTxtBg.classList.add("loaded-main-txt")
        mainTxtWrapper.classList.add("loaded-main-txt-wrapper")
        mainTxtParagraph.classList.add("loaded-main-txt-p")
    }

    function setCookie () {
        d.setTime(d.getTime() + (1 * 10 * 60 * 1000));
        let url = window.location.pathname

        document.cookie = url + "=true;expires=" + d.toUTCString() + ";path=/;"
    }

    function findCookieMach () {
        let cookies = document.cookie.split(" ")
        for (let i = 0; i < cookies.length; i++) {
            if (cookies[i].split("=")[0] === window.location.pathname) return true
        }
        return false
    }

    useEffect(() => {
        const navBar = document.getElementById("nav_menu")
        if (navStatus) {
            navBar.classList.add("header__navbar_active")
        } else if (!navStatus) {
            navBar.classList.remove("header__navbar_active")
            document.getElementById("check").checked = false
        }
    }, [navStatus])

    let lastScroll = 0;

    useEffect(() => {
        setNavBarPosition()
    })

    function setNavBarPosition () {
        const navBar = document.getElementById("headerNavBar");

        window.addEventListener("scroll", () => {
            let currentScroll = window.scrollY;

            if(navStatus) currentScroll = 42;

            if (currentScroll < 44) {
                navBar.classList.remove("header__navbar_is-hidden")
                navBar.classList.add("header__navbar_is-shown")
                return
            }

            if (currentScroll > lastScroll && navBar.classList.contains("header__navbar_is-shown")) {
                navBar.classList.remove("header__navbar_is-shown")
                navBar.classList.add("header__navbar_is-hidden")
            } else if (currentScroll < lastScroll && !navBar.classList.contains("header__navbar_is-shown")) {
                navBar.classList.remove("header__navbar_is-hidden")
                navBar.classList.add("header__navbar_is-shown")
            }

            lastScroll = currentScroll;
        })
    }

    function checkIfWeAreHere (pathName) {
        window.location.pathname === pathName ? setNavStatus(false) : setNavStatus(false)
    }

    function rollUpProjectList () {
        let list = document.getElementById("project-list")

        if (list.classList.contains("hide-project-list")) {
            list.classList.remove("hide-project-list")
        } else {
            list.classList.add("hide-project-list")
        }
    }

    return (
        <header className="header">
            <div id="headerNavBar" className="header__navbar header__navbar_is-shown">
                <div className="header__navbar_wrapper">
                    <Burger
                        navStatus={navStatus}
                        setNavStatus={setNavStatus}
                    />

                    <div className="header__logo">
                        <img style={{transform: "scale(0.06)"}} src="Ksenia_Design_logo_white.svg" alt="#"/>
                    </div>

                    <a href="/#footer" className="header__contacts">Contacts</a>
                </div>

                <div id="nav_menu" className="header__navbar_menu header__navbar_active">
                    <nav>
                        <li><Link to='/' onClick={() => checkIfWeAreHere("/")}>Home</Link></li>
                        <li><a onClick={() => rollUpProjectList()}>Projects</a></li>
                            <ul id="project-list" className="header__navbar_project-list hide-project-list">
                                <li><Link to='/nottinghill' onClick={() => checkIfWeAreHere("/nottinghill")}>- Notting Hill</Link></li>
                                <li><Link to='/chelsea' onClick={() => checkIfWeAreHere("/chelsea")}>- Chelsea</Link></li>
                                <li><Link to='/twickenham' onClick={() => checkIfWeAreHere("/twickenham")}>- Twickenham</Link></li>
                            </ul>
                        <li><Link to='/press' onClick={() => checkIfWeAreHere("/press")}>Press</Link></li>
                        <li><Link to='/about' onClick={() => checkIfWeAreHere("/about")}>About Us</Link></li>
                        <li><a href='/#footer' onClick={() => checkIfWeAreHere("/")}>Contact</a></li>
                    </nav>
                </div>
            </div>

            <div></div>

            <div className={`background ${bgName}-mob`}>
                <div className="background__blanket"></div>
            </div>

            <div id="main-text-bg" style={{transform: "scale(1.2)"}} className="header__main-text">
                <div id="main-text-wrapper" className="header__main-text_wrapper">
                    <p  id="main-text-paragraph"
                        style={ bgName === "home-bg" ? {} : {
                            textTransform: "uppercase",
                            letterSpacing: "0.1em",
                            fontSize: "8.5vw"
                        }}
                    >{pageTitle}</p>
                </div>
            </div>

            {/*<img className="header__arrow-mob" src="flat_arrow.svg" height="50px" width="100px" alt="#"/>*/}
        </header>
    )
}