import "../../styles/nottingPage.scss"

export const EighthNottingRoom = () => (
    <section className="eighth-nt-room">
        <div className="eighth-nt-room__wrapper">
            <img
                className="opacity-animation-1_5s"
                style={{width: "42.4vw", height: "56.35vw"}}
                src="nottingHill/eisec-left.jpg"
                alt="#"
            />

            <img
                style={{width: "28.64vw", height: "28.64vw"}}
                className="eighth-nt-room__right opacity-animation-2s"
                src="nottingHill/eisec-right.jpg"
                alt="#"
            />

            <div className="eighth-nt-room__line opacity-animation-3s"></div>
        </div>
    </section>
)
