import "../../styles/twickenhamPage.scss"

export const SeventhChelseaRoom = () => {
    return (
        <section className="seventh-ch-room photo-section">
            <div className="seventh-ch-room__wrapper wrapper">
                <img
                    style={{width: "49vw", height: "65.1vw"}}
                    className="seventh-ch-room__left opacity-animation-2_5s"
                    src="Chelsea/SeventhLeft_light.jpg"
                    alt="#"
                />

                <div className="seventh-ch-room__right">
                    <img
                        className="opacity-animation-1_5s"
                        style={{width: "22.65vw", height: "30.2vw"}}
                        src="Chelsea/SeventhRightTop_light.jpg"
                        alt="#"
                    />
                    <div className="seventh-ch-room__line line opacity-animation-3s"></div>
                    <img
                        className="opacity-animation-2s"
                        style={{width: "22.65vw", height: "30.2vw"}}
                        src="Chelsea/SeventhRightBot_light.jpg"
                        alt="#"
                    />
                </div>
            </div>
        </section>
    )
}