import "../../styles/nottingHillPageMob.scss"
import {useInView} from "react-intersection-observer";

export const FourthNottingRoomMob = () => {

    const options = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(options)
    const [ref2, inView2] = useInView(options)

    return (
        <section className="fourth-nt-room-mob">
            <div className="fourth-nt-room-mob__wrapper">
                <div ref={ref1} className="fourth-nt-room-mob__top">
                    {inView1 &&
                        <img
                            className="fourth-nt-room-mob__top_img opacity-animation-1_5s"
                            style={{width: "40vw", height: "51.3vw"}}
                            src="nottingHill/frsec-top-first-mob.jpg"
                            alt="#"
                        />
                    }

                    {inView1 && <div className="fourth-nt-room-mob__line line opacity-animation-2_5s"></div>}

                    {inView1 &&
                        <img
                            className="fourth-nt-room-mob__top_img opacity-animation-1_5s"
                            style={{width: "40vw", height: "51.3vw"}}
                            src="nottingHill/frsec-top-second-mob.jpg"
                            alt="#"
                        />
                    }
                </div>

                <div ref={ref2} className="fourth-nt-room-mob__bottom opacity-animation-2s">
                    {inView2 &&
                        <img
                            style={{width: "92vw", height: "64.6vw"}}
                            src="nottingHill/frsec-bot-mob.jpg"
                            alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}