import "../styles/footer.scss"
import {Link} from "react-router-dom";

export const GoForwardFooterMob = ({folderName, heading, link}) => {
    return (
        <footer className="gf-footer-mob">
            <Link to={`/${link}`} className="gf-footer-mob__wrapper">
                <p>{heading}</p>
                <p>View More</p>
                <img src={`${folderName}/FooterMob.jpeg`} alt="#"/>
            </Link>

            <img className="gf-footer-mob__arrow" src="arrow_bold.svg" alt="#"/>
        </footer>
    )
}