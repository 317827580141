import "../../styles/twickenhamPage.scss"
import {useEffect} from "react";

export const FifthTwickenhamRoom = () => {
    return (
        <section className="fifth-tw-room photo-section">
            <div className="fifth-tw-room__wrapper">
                <img
                    src="TwicknhamPage/TwickenhamFifRoomLeft.jpg"
                    style={{width: "42.3vw", height: "61.1vw"}}
                    className="fifth-tw-room__left opacity-animation-2s"
                    alt="#"
                />

                <div className="fifth-tw-room__right">
                    <img
                        className="opacity-animation-3s"
                        src="TwicknhamPage/TwickenhamFifRoomRight.jpg"
                        style={{width: "18.85vw", height: "52.6vw"}}
                        alt="#"
                    />

                    <div className="fifth-tw-room__line opacity-animation-3_5s"></div>
                </div>
            </div>
        </section>
    )
}