import "../../styles/twickenhamPage.scss"

export const SixthChelseaRoom = () => {
    return (
        <section className="sixth-ch-room photo-section">
            <div className="sixth-ch-room__wrapper">
                <div className="sixth-ch-room__left opacity-animation-1_5s">
                    <img
                        style={{width: "36.45vw", height: "26.5vw"}}
                        src="Chelsea/SixthLeft_light.jpg"
                        alt="#"
                    />

                    <p>
                        We find immense joy in designing spaces that cater to individuals of all ages and hold a love for
                        creating enchanting environments for children. It grants us the freedom to unleash our
                        boundless imagination, infusing our designs with a delightful touch of playfulness. In this room,
                        the carefully selected wallpapers complement the bespoke, custom made wardrobe, resulting in
                        a visually pleasing composition.
                    </p>
                </div>

                <img
                    style={{width: "48.1vw", height: "72vw"}}
                    className="sixth-ch-room__right opacity-animation-2_5s"
                    src="Chelsea/SixthRight_light.jpg"
                    alt="#"
                />
            </div>
        </section>
    )
}
