import "../../styles/twickenhamPage.scss"
import {useInView} from "react-intersection-observer";

export const EighthTwickenhamRoomMob = () => {

    const optionOne = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(optionOne)
    const [ref2, inView2] = useInView(optionOne)
    const [ref3, inView3] = useInView(optionOne)


    return (
        <section className="eighth-tw-room-mob">
            <div className="eighth-tw-room-mob__wrapper">
                <div ref={ref1} style={{height: "89vw"}}>
                    {inView1 &&
                        <p className="eighth-tw-room-mob__paragraph opacity-animation-1_5s">
                            This room is a testament to our commitment to creating spaces that go beyond mere aesthetics.
                            By tastefully showcasing the client's beloved guitars as decorative accents, we fill the
                            atmosphere with a unique sense of character. These framed instruments cease to be mere
                            objects, transforming into a visual and tangible embodiment of the client's passion and creativity.
                        </p>
                    }
                </div>

                <div className="eighth-tw-room-mob__bottom">
                    <div ref={ref2} style={{height: "60.6vw"}}>
                        {inView2 &&
                            <img
                                style={{width: "92vw", height: "60.6vw"}}
                                src="TwicknhamPage/Twickenham8RoomLeftMob.jpg"
                                className="eighth-tw-room-mob__first opacity-animation-1_5s"
                                alt="#"
                            />
                        }
                    </div>

                    <div ref={ref3} style={{height: "169.3vw"}}>
                        {inView3 &&
                            <img
                                style={{width: "84vw", height: "169.3vw"}}
                                className="eighth-tw-room-mob__last opacity-animation-1_5s"
                                src="TwicknhamPage/Twickenham8RoomRightMob.jpg"
                                alt="#"
                            />
                        }
                    </div>
                </div>

            </div>
        </section>
    )
}