import "../../styles/twickenhamPage.scss"

export const FirstTwickenhamRoom = () => {
    return (
        <section className="first-tw-room">
            <div className="first-tw-room__wrapper">
                <div className="first-tw-room__left">
                    <img className="opacity-animation-2s"
                         style={{width: "54.42vw", height: "40.1vw"}}
                         src="TwicknhamPage/TwickenhamFRoomLeft.jpg"
                         alt="#"
                    />
                </div>

                <div className="first-tw-room__right">
                    <img
                        className="first-tw-room__right_img opacity-animation-3s"
                        style={{width: "16.6vw", height: "21.875vw"}}
                        src="TwicknhamPage/TwickenhamFRoomRightUp.jpg"
                        alt="#"
                    />
                    <img
                        className="first-tw-room__right_img opacity-animation-3s"
                        style={{width: "16.6vw", height: "21.875vw"}}
                        src="TwicknhamPage/TwickenhamFRoomRightDwn.jpg"
                        alt="#"
                    />
                </div>
                <div className="first-tw-room__line opacity-animation-3_5s"></div>
            </div>
        </section>
    )
}