import "../styles/about.scss"
import {Link} from "react-router-dom";
import {useEffect} from "react";

export const AboutMob = () => {
    useEffect(() => {
        animateBg()
    }, [])

    function animateBg () {
        let w = 0
        let mul = 0.6
        const aboutBg = document.getElementById("about-bg")

        setInterval(() => {
            if (w >= 150) return
            if (w >= 95) mul = 0.4
            if (w >= 140) mul = 0.1
            if (w >= 148) mul = 0.04
            w += mul
            aboutBg.style.height = `${w}vw`
        }, 5)
    }

    return (
        <section className="about-mob">
            <div id="about-bg" className="about-whole-wrapper-mob"></div>
            <img style={{width: "71.4vw", height: "80.93vw"}} className="about-mob__portrait" src="team_photo_optimized-home-mob.jpg" alt="#"/>

            <div className="about-mob__wrapper">
                <div className="about-mob__name">
                    <p>Ksenia</p>
                    <img src="curved-line.svg"  alt="#" className="about-mob__name_hr" />
                    <p>Timoshenko</p>
                </div>

                <Link to="/about" className="about-mob__name_btn">
                    <p>View More About</p>
                    <img src="arrow_bold.svg" alt="#"/>
                </Link>
            </div>

        </section>
    )
}