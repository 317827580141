import "../../styles/nottingHillPageMob.scss"
import {useInView} from "react-intersection-observer";

export const EighthNottingRoomMob = () => {

    const optionOne = {
        threshold: 0.1,
        triggerOnce: true
    }

    const [ref1, inView1] = useInView(optionOne)

    return (
        <section className="eighth-nt-room-mob">
            <div className="eighth-nt-room-mob__wrapper">
                <div ref={ref1} style={{height: "70.6vw"}}>
                    {inView1 &&
                        <img
                            style={{width: "42vw", height: "70.6vw"}}
                            src="nottingHill/eisec-left-mob.jpg"
                            className="opacity-animation-1_5s"
                            alt="#"
                        />
                    }
                </div>

                {inView1 && <div className="eighth-nt-room-mob__line line opacity-animation-3s"></div>}

                <div ref={ref1} style={{height: "36vw"}}>
                    {inView1 &&
                        <img
                            style={{width: "36vw", height: "36vw"}}
                            className="eighth-nt-room-mob__last opacity-animation-2_5s"
                            src="nottingHill/eisec-right-mob.jpg"
                            alt="#"
                        />
                    }
                </div>
            </div>
        </section>
    )
}