import "../../styles/nottingPage.scss"

export const SixthNottingRoom = () => (
    <section className="sixth-nt-room photo-section">
        <div className="sixth-nt-room__wrapper">
            <div className="sixth-nt-room__left">
                <img
                    className="sixth-nt-room__left_img opacity-animation-2s"
                    style={{width: "14.8vw", height: "19.375vw"}}
                    src="nottingHill/sixsec-left-fir.jpg"
                    alt="#"
                />
                <img
                    className="sixth-nt-room__left_img opacity-animation-3s"
                    style={{width: "14.8vw", height: "19.375vw"}}
                    src="nottingHill/sixsec-left-sec.jpg"
                    alt="#"
                />
            </div>

            <div className="sixth-nt-room__right">
                <img className="opacity-animation-1_5s"
                     style={{width: "67.5vw", height: "50.625vw"}}
                     src="nottingHill/sixsec-right.jpg"
                     alt="#"
                />
            </div>

            <div className="sixth-nt-room__line opacity-animation-3_5s"></div>
        </div>
    </section>
)
